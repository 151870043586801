import React, { useState } from 'react';
import SideBar from './sideBar';
import TopBar from './topBar';
import BoxContainer from './boxContainer';
import jwtDecode from "jwt-decode";
import Loader from '../../General Components/Loader/';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { permissionNotification, AskNotificationPermission, showNotification } from '../../lib/constants';
import icon from '../../Imgs/favicon.ico';
import './style.scss';
import { apiGetNotSeenNotifications } from '../../lib/apiNotifications';


///aqui se hace conexxion con el ws
let socket = null;
const Container = (props) => {
    const user = jwtDecode(localStorage.token);
    const [city, setCity] = useState(localStorage.city_id ? localStorage.city_id : user.city_id);
    const [notificationCounter, setNotificationCounter] = useState(0);


    const changeCity = (id) => {
        localStorage.setItem("city_id", id);
        setCity(id);
    }

    React.useEffect(() => {
        socket = new WebSocket(`${process.env.REACT_APP_WSS}`);
        // Connection opened
        socket.onopen = function (event) {
            socket.send(JSON.stringify({
                "action": "authenticate",
                "payload": localStorage.token
            }
            ));
        };

        // Listen for messages
        socket.onmessage = (event) => {
            const eventJS = JSON.parse(event.data);
            
            console.log("sockete", eventJS);

            if (eventJS.action === "log" || eventJS.action === "error" || eventJS.action === "serverClosing" || eventJS.action === "ping") {
                return;
            }


            if (eventJS.action === "notification") {
                console.log(eventJS);
                getCounterNotification();
                showNotification("Sonora Vending", eventJS.payload);
            }


        }

        return () => {
            if (socket) {
                socket.close();
            }
        }
    }, [])

    React.useEffect(() => {
        if (permissionNotification === "default") {
            AskNotificationPermission();
        }
    }, [])

    const getCounterNotification = () => {
        apiGetNotSeenNotifications()
            .then(response => {
                if (response.non_viewed) {
                    setNotificationCounter(response.non_viewed);
                }
            })
            .catch(response => {

            })
        
    }


    const clearNotifications = () => {
        setNotificationCounter(0);
    }

    const componentProps = { ...props, user, city, setCity: changeCity, NotificationManager, clearNotifications, notificationCounter }

    return (
        <div id="wrapper" className="toggled">
            <Loader />
            <TopBar {...componentProps} notificationCounter={notificationCounter} />
            <div id="page-content-wrapper" >
                <SideBar notificationCounter={notificationCounter} />
                <div className="page-container">
                    <BoxContainer {...componentProps} />
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
};



export default Container;