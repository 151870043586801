import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiGetDrivers } from '../../../lib/apiUsers';
import { apiGetVehicles } from '../../../lib/apiVehicles';

const EditChoferVehicle = (props) => {
    const { setChofer, setVehicle, onSave, config } = props;
    const [typeaheadChofer, setTypeaheadChofer] = React.useState({ isLoading: false, options: [] });
    const [typeaheadVehicle, setTypeaheadVehicle] = React.useState({ isLoading: false, options: [] });
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedVehicle, setSelectedVehicle] = React.useState(null);

    React.useEffect(() => {
        if (config.driver) {
            let chofer = [];
            chofer.push(config.driver);
            setTypeaheadChofer({ ...typeaheadChofer, options: chofer });
            setSelectedUser(config.driver);
            setChofer(config.driver);
        }

        if (config.vehicle) {
            let vehiculo = [];
            vehiculo.push(config.vehicle);
            setTypeaheadVehicle({ ...typeaheadVehicle, options: vehiculo });
            setSelectedVehicle(config.vehicle);
            setVehicle(config.vehicle);
        }
    }, [config])

    const typeaheadGetChoferes = (search) => {
        console.log("get elements", search);
        setTypeaheadChofer({ isLoading: true });
        //        setOptions([]);
        apiGetDrivers(search, localStorage.city_id, props.date)
            .then(response => {
                setTypeaheadChofer({ isLoading: false, options: response.rows });
            })
            .catch(console.error)
    }

    const typeaheadGetVehicles = (name) => {
        setTypeaheadVehicle({ isLoading: true });
        //        setOptions([]);
        apiGetVehicles({ name, city_id: localStorage.city_id, date: props.date })
            .then(response => {
                setTypeaheadVehicle({ isLoading: false, options: response.rows });
            })
            .catch(console.error)
    }

    const handleChangeVehicleSelected = (elm) => {
        setSelectedVehicle(elm[0]);
        setVehicle(elm[0]);
    }

    const handleChangeChoferSelected = (elm) => {
        setSelectedUser(elm[0]);
        setChofer(elm[0]);
    }

    //console.log("selected driver", defaultSelectedDriver);

    return <div>
        <div className="separate tar">
            <button className="btn btn-danger sv-btn" onClick={props.Edit}><i className="fas fa-times" /> Cancelar</button>
            <button className="btn btn-submit sv-btn" onClick={onSave}>Guardar configuración</button>
        </div>

        <div className="card separate">
            <div className="card-body">

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p className="label-title">Chofer asignado</p>
                        <label className="data"> <em>{config.driver.first_name + ' ' + config.driver.last_name} <small>{(!config.prog_id && config.driver.id > 0) && "default"}</small></em> </label>
                    </div>
                    <div className="col-md-6 col-12">
                        <p className="label-title">Vehículo asignado</p>
                        <label className="data"><em>{config.vehicle.name} <small>{(!config.prog_id && config.vehicle.id > 0) && "default"}</small></em></label>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-12">
                        <p className="label-title">cambiar chofer por</p>
                        <AsyncTypeahead
                            id="AsyncTypeaheadChofer"
                            labelKey='username'
                            multiple={false}
                            minLength={3}
                            onChange={handleChangeChoferSelected}
                            isLoading={typeaheadChofer.isLoading}
                            filterBy={['first_name', 'last_name', 'username']}
                            selectHintOnEnter={true}
                            clearButton={false}
                            searchText="Buscando..."
                            options={typeaheadChofer.options}
                            onSearch={(query) => typeaheadGetChoferes(query)}
                            defaultSelected={(props.config.driver) && [props.config.driver]}
                            renderMenuItemChildren={(option, props) => (
                                <div key={option.id}>
                                    {option.first_name + ' ' + option.last_name} - <small>{option.username}</small>
                                </div>)
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12">
                        <p className="label-title">cambiar vehículo por</p>
                        <AsyncTypeahead
                            id="AsyncTypeaheadVehicle"
                            labelKey='name'
                            multiple={false}
                            minLength={3}
                            onChange={handleChangeVehicleSelected}
                            isLoading={typeaheadVehicle.isLoading}
                            filterBy={['name']}
                            selectHintOnEnter={true}
                            clearButton={false}
                            searchText="Buscando..."
                            options={typeaheadVehicle.options}
                            onSearch={(query) => typeaheadGetVehicles(query)}
                            defaultSelected={props.config.vehicle && [props.config.vehicle]}
                            renderMenuItemChildren={(option, props) => (
                                <div key={option.id}>
                                    {option.name}
                                </div>)
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default EditChoferVehicle;