import React, { useState } from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';
import { apiForgotPassword } from '../../lib/apiAuth.js';
import logo from '../../Imgs/logo-mmnt.png';
import Loader from '../../General Components/Loader/';
import './login.scss';

const ForgotPassword = (props) => {

    const [formValues, changeValue] = useState({ email: ''});
    const [showLoader, setShowLoader] = useState(false);

    function ChangeValues(e) {
        const { name, value } = e.target;
        changeValue({ ...formValues, [name]: value });
    };

    const Submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            setShowLoader(true);
            apiForgotPassword({ email: formValues.email })
                .then(response => {
                    setShowLoader(false);
                    if (response.message !== "Se ha enviado un correo con un link para recuperar contraseña") {
                        NotificationManager.error('Ocurrió un error', 'Error de validación', 4000);
                    }
                    else {
                        NotificationManager.success('Se ha enviado un correo con un link para recuperar contraseña', '¡Éxito!', 7000);
                    }
                })
                .catch(error => {
                    setShowLoader(false);
                    NotificationManager.error('Inténtelo más tarde.', 'Ocurrió un error', 4000)
                })
        }
        else {
            NotificationManager.error('Correo no válido', 'Error de validación', 4000);
        }
    };

    return (
        <div className="login">
            <div className="container">
                <div className="card" >
                    <div className="card-body">
                        <img src={logo} alt="SOnoraVending" />
                        <div className="tac">
                            <label className="welcome">Ingrese Correo electrónico para recuperar contraseña. </label>
                        </div>

                        <form onSubmit={Submit}>
                            <div className="form-group">
                                <label className="label-title">Correo Electrónico</label>
                                <input
                                    name="email"
                                    id='email'
                                    value={formValues.email}
                                    type="email"
                                    onChange={ChangeValues}
                                    className="form-control"
                                    required
                                    placeholder="Escribe tu nombre de usuario"
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Recuperar</button>

                            <div className="forgot-password">
                                <Link to="/login">Iniciar Sesión</Link>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <NotificationContainer />
            <Loader showLoader={showLoader} />
        </div>
    );
};

export default ForgotPassword;