import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'product/';

export const apiGetProducts = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleProduct = async (id, id_city) => {
    let url = `${urlAPI}?id=${id}&city_id=${id_city}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewProduct = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateProduct = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGenerateProductBarCode = async (id) => {
    let url = urlAPI + id+'/barcode';
    const request = await CreateAuthRequest(GET, null, false);
    return fetch(url, request)
        .then(response => response.blob())
}

export const apiGetLogsProduct = async (id, obj) => {
    let url = `${urlAPI}${id}/log?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}