import React from 'react';
import { withRouter } from 'react-router-dom';
import { apiGetSingleMachine } from '../../lib/apiMachines';
import ModalAdd from '../../General Components/Catalogo/modalAdd';
import FormAdd from './addMachine';

import moment from 'moment';
import LogsTable from '../../General Components/LogsTable/logsTable';
import Inventory from '../../General Components/Inventory/inventory';
import CodePreview from '../../General Components/CodePreview/codePreview';
import { apiGetInventoryWarehouse } from '../../lib/apiWarehouses';

const reducer = (state, action) => {
    switch (action.type) {
        case "setMachine":
            return { ...state, machine: action.payload };
        case "setShow":
            return { ...state, show: action.payload }
        case "setFilters":
            return { ...state, filters: action.payload }
        case "setUpdateCode":
            return { ...state, shouldUpdateCode: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    machine: { email: '', name: '', last_name: '', role_id: 3, balance: 0 },
    show: false,
    filters: { page: 1, limit: 10 },
    shouldUpdateCode: true
};

const Details = (props) => {
    const { id } = props.match.params;
    const { NotificationManager } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { machine, show, filters, shouldUpdateCode } = state;

    React.useEffect(() => {
        apiGetSingleMachine(id)
            .then(response => {
                if (response.type) {

                }
                else {
                    dispatch({ type: 'setMachine', payload: response });
                }
            })
    }, [id])

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    const handleClose = (obj) => {
        if (obj) {
            dispatch({ type: 'setMachine', payload: obj })
            NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
            dispatch({ type: 'setUpdateCode', payload: true })
        }
        dispatch({ type: 'setShow', payload: false });
    }

    const showModal = (obj) => {
        dispatch({ type: 'setShow', payload: true });
    }
    const setPage = (page) => {
        dispatch({ type: 'setFilters', payload: { ...filters, page } });
    }
    const setShouldUpdateCode = (status) => {
        dispatch({ type: 'setUpdateCode', payload: status })
    }

    return (
        <div className="details">
            <div className="row">
                <div className="col-md-7">
                    <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles de máquina</h3>
                </div>
                <div className="col-md-5 tar">
                    <button className="btn btn-primary sv-btn"><i className="fas fa-download" /> Reporte de ventas</button>
                    <button className="btn btn-danger sv-btn"><i className="far fa-trash-alt" /> Eliminar</button>
                    <button className="btn btn-success sv-btn" onClick={() => showModal(machine)}><i className="fas fa-edit" /> Editar</button>
                </div>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <p className="label-title">Nombre de máquina</p>
                            <label className="data">{machine.name}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Número de serie</p>
                            <label className="data">{machine.serial_number}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Dirección máquina</p>
                            <label className="data">{machine.address}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Contador</p>
                            <label className="data">{machine.counter}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Monto en máquina</p>
                            <label className="data">${parseFloat(machine.balance).format(2)}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Última actualización</p>
                            <label className="data">{moment(machine.updatedAt).format('DD/MMM/YYYY hh:mm a')}</label>
                        </div>
                    </div>
                </div>
            </div>

            <CodePreview id={id}
                shouldUpdate={shouldUpdateCode}
                setShouldUpdate={setShouldUpdateCode}
            />


            <Inventory
                GetData={apiGetInventoryWarehouse}
                id={id}
                isMachine={true}
                NotificationManager={NotificationManager}
            />


            <LogsTable
                id={id}
            />
            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={machine}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nueva máquina'}
                titleEdit={'Editar máquina'}
                {...props}
            />

        </div>)
}

export default withRouter(Details);