import React from 'react';
import useDebounce from '../CustomHooks/useDebounce';
import VirtualizedList from '../VirtualizedList/virtualizedList';


const reducer = (state, action) => {
    switch (action.type) {
        case 'setResults':
            return { ...state, results: action.payload }
        case 'setSearch':
            return { ...state, search: action.payload }
        case 'setItemSelected':
            return { ...state, selectedItem: action.payload }
        case 'setFilters':
            return { ...state, filters: action.payload }
        case 'setHasNextPage':
            return { ...state, hasNextPage: action.payload }
        case 'setIsNextPageLoading':
            return { ...state, isNextPageLoading: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    selectedItem: { id: 0 },
    search: '',
    results: { count: 0, rows: [] },
    filters: { page: 1, limit: 10 },
    hasNextPage: false,
    isNextPageLoading: false
};

const SearchList = (props) => {
    const { onSearch, onSelect, labelKey, idKey, functSearch } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { search, selectedItem, results, filters, hasNextPage, isNextPageLoading } = state;

    const debouncedValue = useDebounce(search, 300);
    const [lastSearch, setLastSearch] = React.useState(null);

    React.useEffect(() => {
        dispatch({ type: 'setFilters', payload: { page: 1, limit: 10 } });
        dispatch({ type: 'setSearch', payload: '' });
        loadNextPage();
    }, [functSearch]);

    React.useEffect(() => {
        if (debouncedValue !== lastSearch) {
            dispatch({ type: 'setIsNextPageLoading', payload: true });
            functSearch({ name: debouncedValue, page: 1, limit: 10 })
                .then(response => {
                    if (response.error) {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                    }
                    else {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                        dispatch({ type: 'setResults', payload: { count: response.count, rows: response.rows } })
                        const pages = response.count / 10;
                        dispatch({ type: 'setHasNextPage', payload: 1 < pages });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'setIsNextPageLoading', payload: true });
                    console.error()
                })
        }
    }, [functSearch, debouncedValue, lastSearch])

    const HandleSelectItem = (item) => {
        onSelect([item]);
        dispatch({ type: 'setItemSelected', payload: item });
    }

    const handleChangeSearch = (e) => {
        dispatch({ type: 'setSearch', payload: e.target.value });
    }

    const loadNextPage = () => {

        dispatch({ type: 'setIsNextPageLoading', payload: true });
        functSearch({ name: debouncedValue, ...filters })
            .then(response => {
                if (response.error) {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                }
                else {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                    dispatch({ type: 'setResults', payload: { count: response.count, rows: [...results.rows, ...response.rows] } })
                    const pages = response.count / filters.limit;
                    dispatch({ type: 'setHasNextPage', payload: filters.page < pages });
                }
            })
            .catch((error) => {
                dispatch({ type: 'setIsNextPageLoading', payload: true });
                console.error()
            })
        dispatch({ type: 'setFilters', payload: { ...filters, page: filters.page + 1 } });
    }

    const loadContent = (item) => {
        return <li className={"list-group-item " + (item[idKey] === selectedItem[idKey] && "active")} onClick={() => HandleSelectItem(item)}>{item[labelKey]}</li>

    }
    //console.log(results, hasNextPage);

    return (<div>
        <div>
            <input className="form-control" value={search} onChange={handleChangeSearch} />
        </div>


        {VirtualizedList({ hasNextPage, isNextPageLoading, list: results.rows, loadNextPage, loadContent })}

        {/* <ul className="list-group separate">
            {results.map(row =>
                <li key={row[idKey]} className={"list-group-item " + (row[idKey] === selectedItem[idKey] && "active")} onClick={() => HandleSelectItem(row)}>
                    {row[labelKey]}
                </li>
            )}
        </ul> */}
    </div>)
}

// SearchList.defaultProps = {
//     loadContent: (item, idKey, selectedItem) => {
//         return <li className={"list-group-item " + (item[idKey] === selectedItem[idKey] && "active")} onClick={() => HandleSelectItem(item)}>{item[labelKey]}</li>
//     }
// }

export default SearchList;