import React from 'react';
import { apiGetNotifications } from '../../lib/apiNotifications';
import moment from 'moment';
import Table from '../../General Components/Datatable/table';
import Pagination from '../../General Components/Pagination/pagination';


const Notifications = (props) => {
    const [data, setData] = React.useState({ count: 0, rows: [] });
    const [filters, setFilters] = React.useState({ page: 1, limit: 10 });

    React.useEffect(() => {
        apiGetNotifications(filters)
            .then(response => {
                if (response.type || response.error) {

                }
                else {
                    console.log("props.notificationCounter", props.notificationCounter);
                    if (props.notificationCounter > 0) {
                        props.clearNotifications();
                    }
                    setData(response);
                }
            })
    }, [filters])

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });


    const setPage = (page) => {
        setFilters({ ...filters, page });
    }

    return (
        <div className="details">
            <h3>Alertas</h3>
            <div className="card separate">
                <div className="card-body">
                    <div className="table-responsive">
                        <Table
                            columns={['Fecha de creación', "Descripción"]}
                            data={data.rows.map(n => ({ createdAt: moment(n.createdAt).format("DD/MMM/YYYY HH:MM a"), description: n.description }))}
                            tableClass='table table-triped'
                        />
                    </div>
                    <Pagination
                        total={data.count}
                        page={filters.page}
                        limit={filters.limit}
                        pageClick={setPage}
                    />
                </div>
            </div>
        </div>)
}

export default Notifications;