import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../Modules/Login/login';
import Forgot from '../Modules/Login/forgotPassword';
import ResetPassword from '../Modules/Login/recoveryPassword';
import PrivateRoute from './privateRoute';

const AppRoutes = () =>
    <Fragment>
        <Switch>
            <Route path="/login" component={Login} />
            {/* <Route path="/login" component={Login} /> */}
            <Route path="/Olvide-mi-contrasena" component={Forgot} />
            <Route exact={true} path='/cambiar-contraseña/:token' component={ResetPassword} />
            <PrivateRoute></PrivateRoute>
        </Switch>
    </Fragment>;

export default AppRoutes;