import React from 'react';
import { withRouter } from 'react-router-dom';
import { apiGetSingleRoute, apiGetConfigRoute } from '../../../lib/apiRoutes';
//import { NotificationContainer, NotificationManager } from 'react-notifications';
import ModalAdd from '../../../General Components/Catalogo/modalAdd';
import FormAdd from '../addRoute';
//import LogsTable from '../../../General Components/LogsTable/logsTable';
import EditMachinesInRoute from './sectionEditRoute';
import MachinesInRoute from './detailsMachinesInRoute';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../calendar.scss';
import loaderConnect from '../../../General Components/Loader/loaderConnect';
import { apiGetMonthProgram } from '../../../lib/apiRoutes';
import DefaultWeek from './defaultWeek';

const reducer = (state, action) => {
    switch (action.type) {
        case "setRoute":
            return { ...state, route: action.payload };
        case "setShow":
            return { ...state, show: action.payload }
        case "setIsEditing":
            return { ...state, isEditing: action.payload }
        case 'setShowModalChofer':
            return { ...state, shoModalChofer: action.payload }
        case 'setShowModalVehiculo':
            return { ...state, shoModalVehiculo: action.payload }
        case 'preExistente':
            return { ...state, preExistente: action.payload }
        case 'setCalendarEvents':
            return { ...state, calendarEvents: action.payload }
        case 'setLastDay':
            return { ...state, lastDay: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    route: { email: '', name: '', last_name: '', role_id: 3 },
    show: false,
    isEditing: false,
    preExistente: false,
    config: {
        vehicle: {
            id: 0,
            name: "Sin Asignar"
        },
        driver: {
            id: 0,
            first_name: "Sin",
            last_name: "Asignar",
            username:"SinAsignar"
        },
        scheduled_date: moment().toISOString(),
        machines: []
    },
    calendarEvents: [],
    lastDay: moment().endOf("month").format("YYYY-MM-DD")
};

const Details = (props) => {
    const { id } = props.match.params;
    const { showLoader, NotificationManager } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { route, show, isEditing, calendarEvents, lastDay } = state;

    const [config, setConfig] = React.useState(initialState.config);

    const getSingleRoute = React.useCallback(() => {
        showLoader(true);
        apiGetSingleRoute(id)
            .then(response => {
                if (response.type) {
                    //
                } else {
                    dispatch({ type: 'setRoute', payload: response });
                }
                showLoader(false);
            })
            .catch(() => {
                showLoader(false);
            })
    }, [id, showLoader]);

    React.useEffect(() => {
        getSingleRoute();
    }, [getSingleRoute]);

    React.useEffect(() => {
        showLoader(true);
        apiGetConfigRoute(id, config.scheduled_date)
            .then(response => {
                if (response.prog_id) {
                    setConfig(response);
                }
                else {
                    let obj = { ...initialState.config, scheduled_date: config.scheduled_date, prog_id: null }
                    
                    if (response.driver) {
                        obj.driver = response.driver;
                    }

                    if (response.vehicle) {
                        obj.vehicle = response.vehicle;
                    }

                    setConfig(obj)
                }
                showLoader(false);
            })
            .catch(() => {
                showLoader(false);
            })
    }, [id, config.scheduled_date])

    React.useEffect(() => {
        apiGetMonthProgram(id, lastDay)
            .then(response => {
                if (response.error) {

                }
                else {
                    dispatch({ type: 'setCalendarEvents', payload: response })
                }
            })
    }, [id, lastDay, config.scheduled_date])

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ],
        weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
        weekdaysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
        weekdaysParseExact: true,
    });

    const handleClose = (obj) => {
        if (obj) {
            dispatch({ type: 'setRoute', payload: obj })
            NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
        }
        dispatch({ type: 'setShow', payload: false });
    }

    const showModal = (obj) => {
        dispatch({ type: 'setShow', payload: true });
    }

    const EditMachinesRoute = () => {
        console.log("edit");
        dispatch({ type: 'setIsEditing', payload: !isEditing });
    }

    const setDay = (day) => {
        setConfig({ ...config, scheduled_date: moment(day).toISOString() });
        dispatch({ type: 'setLastDay', payload: moment(day).endOf("month").format("YYYY-MM-DD") })
    }

    const SaveRouteSettings = (newConfig) => {
        setConfig(newConfig);
        EditMachinesRoute();
    }

    return (
        <div className="details">
            <div >
                <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles de ruta</h3>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <p className="label-title">Nombre ruta</p>
                            <label className="data">{route.name} </label>
                        </div>
                        <div className="col-md-8 col-12 tar">
                            <button className="btn btn-success sv-btn" onClick={() => showModal(route)}><i className="fas fa-edit" /> Editar nombre</button>
                        </div>
                    </div>
                </div>
            </div>

            {
                !isEditing ?
                    <MachinesInRoute id={id}
                        Edit={EditMachinesRoute}
                        config={config}
                        setDay={setDay}
                        showLoader={showLoader}
                        calendarEvents={calendarEvents}
                    />
                    :
                    <EditMachinesInRoute
                        Edit={EditMachinesRoute}
                        id={id}
                        config={config}
                        SaveRouteSettings={SaveRouteSettings}
                        showLoader={showLoader}
                        NotificationManager={NotificationManager}
                    />
            }

            {/* <LogsTable
                GetData={() => { }}
            /> */}

            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={route}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nueva ruta'}
                titleEdit={'Editar ruta'}
                {...props}
            />

            {!isEditing && route.default_prog && (
                <DefaultWeek programacion={route.default_prog} config={config} afterSave={getSingleRoute} />
            )}
        </div>)
}

export default withRouter(loaderConnect(Details));
