import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalAdd = (props) => {
    const [IsEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
            }
        }
        else {
            setIsEditing(false);
        }
    }, [props.editElement]);


    // let form = forms.find(form => form.module === props.module);

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{IsEditing ? props.titleEdit : props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* {
                    forms.find(form => form.module === props.module).form
                } */}
                {
                    props.component(props)
                }
            </Modal.Body>
        </Modal>
    )
}

ModalAdd.defaultProps = {
    editElement: null,
    show: false,
    handleClose: () => { },
    component: (propss) => <div {...propss} />,
    city: 0,
    title: 'Crear nuevo',
    titleEdit: 'Editar',
    showLoader:()=>{}
}



export default ModalAdd;