import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
//import jwtDecode from "jwt-decode";
import UsersList from '../Users/usersList';
import MachinesList from '../Machines/machinesList';
import VehiclesList from '../Vehicles/vehiclesList';
import RoutesList from '../Routes/routesList';
import Warehouses from '../Warehouses/warehousesList';
import PoductsList from '../Products/productsList';
import Alerts from '../Notifications/notifications';
import GenerateReport from '../Reports/generateReports';
import Monitor from '../Monitor/monitor';

// import Details from '../../General Components/Details/details';
import DetailsUser from '../Users/usersDetails';
import DetailsMachine from '../Machines/detailsMachines';
import DetailsWarehouse from '../Warehouses/detailsWarehouse';
import DetailsVehicles from '../Vehicles/detailsVehicle';
import DetailsPoduct from '../Products/detailsProduct';
import DetailsRoute from '../Routes/Details/detailsRoute';





const BoxContainer = (props) => {
    //const decoded = jwtDecode(localStorage.token);

    const route = [
        { path: "/monitor", exact: true, component: () => <Monitor {...props} />, permissions: null },
        { path: "/usuarios/:id", exact: true, component: () => <DetailsUser {...props} />, permissions: null },
        { path: "/usuarios", exact: true, component: () => <UsersList {...props} />, permissions: null },
        { path: "/maquinas/:id", exact: true, component: () => <DetailsMachine {...props} />, permissions: null },
        { path: "/maquinas", component: () => <MachinesList {...props} />, permissions: null },
        { path: "/vehiculos/:id", exact: true, component: () => <DetailsVehicles {...props} />, permissions: null },
        { path: "/vehiculos", component: () => <VehiclesList {...props} />, permissions: null },
        { path: "/rutas/:id/edit", exact: true, component: () => <DetailsRoute {...props} />, permissions: null },
        { path: "/rutas/:id", exact: true, component: () => <DetailsRoute {...props} />, permissions: null },
        { path: "/rutas", exact: true, component: () => <RoutesList {...props} />, permissions: null },
        { path: "/almacenes/:id", exact: true, component: () => <DetailsWarehouse {...props} />, permissions: null },
        { path: "/almacenes", component: () => <Warehouses {...props} />, permissions: null },
        { path: "/productos/:id", exact: true, component: () => <DetailsPoduct {...props} />, permissions: null },
        { path: "/productos", exact: true, component: () => <PoductsList {...props} />, permissions: null },
        { path: "/alertas", exact: true, component: () => <Alerts {...props} />, permissions: null },
        { path: "/reportes", exact: true, component: () => <GenerateReport {...props} />, permissions: null },
    ];

    return <Switch >
        <Redirect exact={true} from='/' to='/monitor' />
        {
            route.map(elm =>
                <Route key={elm.path} path={elm.path} exact={elm.exact} component={elm.component} />
            )
        }
        <Route path='*' exact={true} component={() => <Monitor />} />
    </Switch >
}


export default BoxContainer;

