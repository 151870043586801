import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Container from '../Modules/Container/Container';

const PrivateRoute = () => {
    const token  = window.localStorage.token;
    const RedirectToLogin = () => {
        localStorage.removeItem('token');
        return <Redirect to="/login"></Redirect>
    }
    return (
        token && token !== 'undefined' ?
            <Route path="/" component={Container} />
            :
            RedirectToLogin()
    )
};

export default PrivateRoute;