import React from 'react';
import VirtualizedList from './virtualizedList';
import useDebounce from '../../General Components/CustomHooks/useDebounce';
import moment from 'moment';
import { apiGetRoutes } from '../../lib/apiRoutes';
import { CreateAuthRequest as CreateRequest, POST, baseUrl, makeUrlGET } from '../../lib/constants';
import FileSaver from 'file-saver';
import DateTime from 'react-datetime';
import loaderConnect from '../../General Components/Loader/loaderConnect';
import './report.scss';

import '../../styles/datetime.scss';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setResults':
            return { ...state, results: action.payload }
        case 'setSearch':
            return { ...state, search: action.payload }
        case 'setItemSelected':
            return { ...state, selectedItem: action.payload }
        case 'setFilters':
            return { ...state, filters: action.payload }
        case 'setHasNextPage':
            return { ...state, hasNextPage: action.payload }
        case 'setIsNextPageLoading':
            return { ...state, isNextPageLoading: action.payload }
        case 'setSelectedData':
            return { ...state, selectedData: action.payload }
        case 'setInitDate':
            return { ...state, init_date: action.payload };
        case 'setFinalDate':
            return { ...state, final_date: action.payload };
        default:
            return { ...state }
    }
}

const initialState = {
    selectedItem: { id: 0 },
    search: '',
    results: { count: 0, rows: [] },
    filters: { page: 1, limit: 10 },
    hasNextPage: false,
    isNextPageLoading: false,
    selectedData: null,
    init_date: moment().startOf('month'),
    final_date: moment()

};

const GenerateReport = (props) => {

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { search, results, filters, hasNextPage, isNextPageLoading, selectedItem, init_date, final_date } = state;

    const debouncedValue = useDebounce(search, 300);
    const [lastSearch, setLastSearch] = React.useState('');

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    React.useEffect(() => {
        loadNextPage();
    }, []);

    React.useEffect(() => {
        if (debouncedValue !== lastSearch) {
            apiGetRoutes({ name: debouncedValue, page: 1, limit: 10 })
                .then(response => {
                    if (response.error) {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                    }
                    else {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                        dispatch({ type: 'setResults', payload: { count: response.count, rows: response.rows } })
                        const pages = response.count / 10;

                        dispatch({ type: 'setHasNextPage', payload: 1 < pages });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'setIsNextPageLoading', payload: true });
                    console.error()
                })
            dispatch({ type: 'setFilters', payload: { limit: 10, page: 1 } });
            setLastSearch(debouncedValue);
        }
    }, [debouncedValue, lastSearch])

    const loadNextPage = () => {

        dispatch({ type: 'setIsNextPageLoading', payload: true });
        apiGetRoutes({ name: debouncedValue, ...filters, date: moment().toISOString(), city_id: localStorage.city_id })
            .then(response => {
                if (response.error) {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                }
                else {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                    dispatch({ type: 'setResults', payload: { count: response.count, rows: [...results.rows, ...response.rows] } })
                    const pages = response.count / filters.limit;

                    dispatch({ type: 'setHasNextPage', payload: filters.page < pages });
                }
            })
            .catch((error) => {
                dispatch({ type: 'setIsNextPageLoading', payload: true });
                console.error()
            })
        dispatch({ type: 'setFilters', payload: { ...filters, page: filters.page + 1 } });
    }

    const loadContent = (item) => {
        return { className: item.id === selectedItem.id ? 'list-group-item active' : 'list-group-item', content: <label onClick={() => selectRoute(item)}>{item.name}</label> }
    }

    const valid = current => {
        const today = moment().endOf('day');
        return today.isAfter(current);
    };

    const handleChangeDTP = (name, value) => {
        if (name === 'start') {
            dispatch({ type: 'setInitDate', payload: moment(value) });
        }
        else {
            dispatch({ type: 'setFinalDate', payload: moment(value) });
        }

    }

    const selectRoute = (item) => {
        dispatch({ type: 'setItemSelected', payload: item })
    }

    const Download = async () => {

        if (selectedItem.id > 0) {
            props.showLoader(true);
            const name = "reporte de ventas " + selectedItem.name + '_' + init_date.format("DD/MMM/YYYY") + "-" + final_date.format("DD/MMM/YYYY");
            const request = await CreateRequest(POST);
            const url = baseUrl + "report?" + makeUrlGET({ init_date: init_date.toISOString(), final_date: final_date.toISOString(), id: selectedItem.id });
            fetch(url, request)
                .then(async response => {
                    if (!response.error) {
                        const file = await response.blob();
                        FileSaver.saveAs(file, name);
                    }
                    props.showLoader(false);
                })
                .catch(error => {
                    console.error(error);
                    props.showLoader(false);
                })
        } else {
            props.NotificationManager.warning("Debes seleccionar una ruta.", 'Ups!', 5000)
        }



    }

    return (
        <div className="details">
            <h3>Generar Reportes</h3>


            <div className="row separate">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <p className="label-title">Seleccione rango de fechas</p>
                            <div className="row separate">
                                <div className="form-group col-md-6">
                                    <p className="label-title">Fecha Inicio</p>

                                    <DateTime closeOnSelect={true}
                                        dateFormat='DD/MMM/YYYY'
                                        placeholder="seleccione fecha"
                                        timeFormat={false}
                                        onChange={moment => handleChangeDTP("start", moment)}
                                        value={init_date}
                                        viewMode='days'
                                        locale="es"
                                        isValidDate={valid}
                                        inputProps={{
                                            required: true
                                        }}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <p className="label-title">Fecha Fin</p>
                                    <DateTime closeOnSelect={true}
                                        dateFormat='DD/MMM/YYYY'
                                        timeFormat={false}
                                        onChange={moment => handleChangeDTP("end", moment)}
                                        value={final_date}
                                        viewMode='days'
                                        locale="es"
                                        isValidDate={valid}
                                        inputProps={{
                                            placeholder: "seleccione fecha",
                                            required: true
                                        }}
                                    />
                                </div>
                            </div>
                            <p className="label-title">Selecciona ruta</p>
                            {VirtualizedList({ hasNextPage, isNextPageLoading, list: results.rows, loadNextPage, loadContent })}

                            <div className="col-md-12 tac">
                                <button className="btn btn-submit sv-btn" onClick={Download}>Crear Reporte</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}


export default loaderConnect(GenerateReport);