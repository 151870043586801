import React from 'react';
import { apiNewMachine, apiUpdateMachine } from '../../lib/apiMachines';

const AddMachineForm = (props) => {
    const [machine, setMachine] = React.useState({
        name: '',
        city_id: props.city,
        serial_number: '',
        address: '',
        counter: 0.0
    });
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
                setMachine(props.editElement);
            }
        }
    }, [props.editElement])

    const submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let obj = machine;



            if (isEditing) {
                props.showLoader(true);
                delete obj.id;
                apiUpdateMachine(props.editElement.id, obj)
                    .then(response => {
                        props.showLoader(false);
                        props.handleClose(response);

                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
            else {
                props.showLoader(true);
                apiNewMachine(obj)
                    .then(response => {

                        //Redireccionar a pagina de detalle
                        if (!response.message) {
                            props.handleClose(obj, "success");
                            props.history.push('/maquinas/' + response.id);
                        }
                        else {
                            props.handleClose(null, "error", response);
                        }
                        props.showLoader(false);
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
        }
    }

    const changeForm = (e) => {
        const { name, value } = e.target;
        setMachine({ ...machine, [name]: value });
    }

    return (
        <form onSubmit={submit}>
            <div className="form-group">
                <label className="label-title">Nombre</label>
                <input name="name" className="form-control" placeholder="Escribe el nombre de la máquina" value={machine.name} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Número de serie</label>
                <input name="serial_number" className="form-control" placeholder="Escribe número de serie" value={machine.serial_number} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Dirrecion</label>
                <input name="address" placeholder="Escribe la dirección " className="form-control" value={machine.address} onChange={changeForm} />
            </div>
            <div className="form-group">
                <label className="label-title">Contador</label>
                <input type="number" name="counter" className="form-control" placeholder="Escribe el contador" value={machine.counter} onChange={changeForm} required />
            </div>
            <div className="tac">
                <button type="submit" className="btn btn-submit sv-btn" >Guardar</button>
            </div>
        </form>
    )
}

export default AddMachineForm;