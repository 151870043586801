import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'vmachine/';

export const apiGetMachines = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleMachine = async (id) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewMachine = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateMachine = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}