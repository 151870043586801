import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'user/';

export const apiGetUsers = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}


export const apiGetDrivers = async (search, city_id, date) => {
    let url = `${urlAPI}drivers?search=${search}&city_id=${city_id}`;
    if (date) {
        url += `&date=${date}`;
    }
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleUser = async (id) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewUser = async (obj) => {

    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateUser = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}