import React from 'react';
import Table from '../Datatable/table';
import Pagination from '../Pagination/pagination';
import ModalMovements from '../ModalMovements/modalMovements';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { apiRegisterInventoryMovement } from '../../lib/apiWarehouses';
import { apiRegisterVehicleInventoryMovement } from '../../lib/apiVehicles';
import { withRouter } from 'react-router-dom';

const reducer = (state, action) => {
    switch (action.type) {
        case "setData":
            return { ...state, data: action.payload }
        case "setFilters":
            return { ...state, filters: action.payload }
        case "setModalMovements":
            return { ...state, modalMovementsState: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    data: { count: 0, rows: [] },
    filters: { page: 1, limit: 10 },
    modalMovementsState: { show: false, title: '', actionType: '' },
    movements: []
};

const modules = [
    { name: "vehiculos" },
    { name: "almacenes" },
    { name: "maquinas" },
]

const Inventory = (props) => {
    const { GetData, id, isMachine, setUpdateLogs, NotificationManager } = props;

    const { pathname } = props.location;
    const [, pathProcess] = pathname.split('/');
    const proceso = modules.find(elm => elm.name === pathProcess);

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { filters, data, modalMovementsState } = state;

    React.useEffect(() => {
        GetData(id, filters)
            .then(response => {
                if (response.type) {

                }
                else {
                    dispatch({ type: 'setData', payload: response });
                }
            })
    }, [id, filters, GetData])

    const setPage = (page) => {
        dispatch({ type: 'setFilters', payload: { ...filters, page } });
    }

    const handleModalMovements = (type, show) => {
        let actionType = type,
            title = type === "merma" ? "Registrar merma" : (type === "salida" ? 'Registrar salida' : "Registrar entrada");

        dispatch({ type: "setModalMovements", payload: { show, title, actionType } });

    }

    const handleSubmitMovement = (obj) => {///////////////////////////////
        if (proceso.name === "almacenes") {
            apiRegisterInventoryMovement(id, obj)
                .then(response => {
                    if (response.errno) {
                        NotificationManager.error('No ha sido posible completar el movimiento.', 'Ocurrió un error :(', 5000)
                    }
                    else {
                        NotificationManager.success('', 'Se ha registrado el movimiento', 5000)
                        dispatch({ type: 'setModalMovements', payload: { show: false, title: '', actionType: '' } });
                        dispatch({ type: 'setFilters', payload: { ...filters } });
                        setUpdateLogs(true);
                    }
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Ocurrió un error', 5000)
                })
        }
        else {
            apiRegisterVehicleInventoryMovement(id, obj)
                .then(response => {
                    if (response.errno) {
                        NotificationManager.error('No ha sido posible completar el movimiento.', 'Ocurrió un error :(', 5000)
                    }
                    else {
                        NotificationManager.success('', 'Se ha registrado el movimiento', 5000)
                        dispatch({ type: 'setModalMovements', payload: { show: false, title: '', actionType: '' } });
                        dispatch({ type: 'setFilters', payload: { ...filters } });
                        setUpdateLogs(true);
                    }
                })
                .catch(error => {
                    NotificationManager.error(error.message, 'Ocurrió un error', 5000)
                })
        }

    }

    return <div className="card separate">
        <div className="card-body">
            <div className="row">
                <div className="col-md-6">
                    <h3>Inventario de productos</h3>
                </div>
                {
                    !isMachine &&
                    <div className="col-md-6 tar">
                        <button className="btn btn-violet sv-btn" onClick={() => handleModalMovements('merma', true)}><i className="fas fa-drumstick-bite" /> Merma</button>
                        <button className="btn btn-submit sv-btn" onClick={() => handleModalMovements('salida', true)}><i className="fas fa-sign-out-alt" /> Registrar salida</button>
                        <button className="btn btn-primary sv-btn" onClick={() => handleModalMovements('entrada', true)}><i className="fas fa-sign-in-alt" /> Registrar entrada</button>
                    </div>
                }
            </div>
            <div className="table-responsive">
                <Table
                    columns={['Producto', "Código", "Cantidad"]}
                    data={data.rows.map(elm => ({ name: elm, code: elm.code, stock: elm.stock }))}
                    tableClass='table table-striped'
                    configTable={{
                        columnDefinition: {
                            name: (elm) => {
                                if (elm.decrease) {
                                    return elm.name + " (merma de máquina)"
                                }
                                else return elm.name;
                            }
                        },
                        columnStyle: { stock: { textAlign: 'right' } }
                    }}
                />
            </div>
            <Pagination
                total={data.count}
                page={filters.page}
                limit={filters.limit}
                pageClick={setPage}
            />
        </div>

        <ModalMovements
            id={id}
            show={modalMovementsState.show}
            title={modalMovementsState.title}
            actionType={modalMovementsState.actionType}
            onHide={() => handleModalMovements('', false)}
            submit={(obj) => handleSubmitMovement(obj)}
        />

        {/* <NotificationContainer /> */}
    </div>
}

Inventory.defaultProps = {
    isMachine: false,
}

export default withRouter(Inventory);