import React from 'react';
import { withRouter } from 'react-router-dom';
import { apiGetSingleProduct } from '../../lib/apiProducts';
import ModalAdd from '../../General Components/Catalogo/modalAdd';
import FormAdd from './addProduct';
import moment from 'moment';
import LogsTable from '../../General Components/LogsTable/logsTable';
import CodePreview from '../../General Components/CodePreview/codePreview';

const reducer = (state, action) => {
    switch (action.type) {
        case "setProduct":
            return { ...state, product: action.payload };
        case "setShow":
            return { ...state, show: action.payload }
        case "setData":
            return { ...state, data: action.payload }
        case "setFilters":
            return { ...state, filters: action.payload }
        case "setUpdateCode":
            return { ...state, shouldUpdateCode: action.payload }
        case "setUpdateLogs":
            return { ...state, updateLogs: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    product: { code: "", name: "", description: "", price: "0.00", },
    show: false,
    data: { count: 0, rows: [] },
    filters: { page: 1, limit: 10 },
    shouldUpdateCode: true,
    updateLogs: true,
};

const Details = (props) => {
    const { id } = props.match.params;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { product, show, filters, data, updateLogs, shouldUpdateCode } = state;
    const { NotificationManager } = props;

    React.useEffect(() => {
        apiGetSingleProduct(id, localStorage.city_id)
            .then(response => {
                if (response.type) {

                }
                else {
                    dispatch({ type: 'setProduct', payload: response });
                }
            })
    }, [id])

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    const handleClose = (obj, type, response) => {
        if (type) {
            if (type === "error") {
                NotificationManager.error('No se ha podido guardar. ' + response.message, 'Ocurrió un error', 7000);
            }
            else {
                dispatch({ type: 'setProduct', payload: obj });
                dispatch({ type: 'setUpdateCode', payload: true });
                NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
                dispatch({ type: 'setShow', payload: false });
            }
        }
        else {
            if (obj) {
                dispatch({ type: 'setProduct', payload: obj });
                dispatch({ type: 'setUpdateCode', payload: true });
                NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
            }
            dispatch({ type: 'setShow', payload: false });
        }
    }

    const showModal = (obj) => {
        dispatch({ type: 'setShow', payload: true });
    }
    const setPage = (page) => {
        dispatch({ type: 'setFilters', payload: { ...filters, page } });
    }
    const setShouldUpdateCode = (status) => {
        dispatch({ type: 'setUpdateCode', payload: status })
    }

    const setUpdateLogs = (payload) => {
        dispatch({ type: 'setUpdateLogs', payload })
    }

    return (
        <div className="details">
            <div className="row">
                <div className="col-md-6">
                    <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles del producto</h3>
                </div>
                <div className="col-md-6 tar">
                    <button className="btn btn-success sv-btn" onClick={() => showModal(product)}><i className="fas fa-edit" /> Editar</button>
                </div>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3 col-12">
                            <p className="label-title">Nombre del producto</p>
                            <label className="data">{product.name}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Código</p>
                            <label className="data">{product.code}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Precio</p>
                            <label className="data">$ {parseFloat(product.price).format(2)}</label>
                        </div>
                        <div className="col-md-3 col-12">
                            <p className="label-title">Descripción</p>
                            <label className="data">{product.description}</label>
                        </div>
                    </div>
                </div>
            </div>

            <CodePreview id={id}
                isProduct={true}
                shouldUpdate={shouldUpdateCode}
                setShouldUpdate={setShouldUpdateCode}
            />

            <LogsTable
                id={id}
                isProduct={true}
                updateLogs={updateLogs}
                setUpdateLogs={setUpdateLogs}
            />

            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={product}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nuevo producto'}
                titleEdit={'Editar producto'}
                {...props}
                setUpdateLogs={setUpdateLogs}
            />
            
        </div>)
}

export default withRouter(Details);