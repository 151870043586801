import React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Table from '../../../General Components/Datatable/table';
import MyEvent from './myEvent';

import moment from 'moment';
const localizer = momentLocalizer(moment);

const reducer = (state, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, visited: action.payload.visited, notVisited: action.payload.notVisited }
        case 'setDate':
            return { ...state, selectedDate: action.payload }
        case 'setEvent':
            return { ...state, events: action.payload }
        default:
            break;
    }
}
const initialState = {
    selectedDate: moment(),
    visited: { count: 0, rows: [] },
    notVisited: { count: 0, rows: [] },
    events: [{
        title: 'Día Seleccionado',
        start: moment().toDate(),
        end: moment().toDate(),
        allDay: true
    }]
}

const messages = {
    showMore: (count) => <div className="rbc-event">Mostrar {count} más</div>,
    week: "Semana",
    day: "Día",
    month: "Mes",
    previous: "Anterior",
    next: "Siguiente",
    today: "Hoy",
    yesterday: "Ayer",
    tomorrow: "Mañana",
    noEventsInRange: "No hay servicios agendados en este rango de fechas"
}

const MachinesInRoute = (props) => {
    const { config, setDay, showLoader, calendarEvents } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { visited, notVisited, selectedDate, events } = state;

    React.useEffect(() => {
        const visitedRow = config.machines.filter(machine => machine.visited);
        const notVisitedRow = config.machines.filter(machine => !machine.visited);
        console.log(config);
        dispatch({
            type: 'setData', payload: {
                visited: { count: visitedRow.length, rows: visitedRow.map(row => ({ name: row.name, options: '' })) },
                notVisited: { count: notVisitedRow.length, rows: notVisitedRow.map(row => ({ name: row.name, options: '' })) }
            }
        });
    }, [config])

    React.useEffect(() => {
        dispatch({ type: 'setDate', payload: moment(config.scheduled_date) });
        //dispatch({ type: 'setEvent', payload: [{ ...events[0], start: moment(config.scheduled_date).toDate(), end: moment(config.scheduled_date).toDate() }] })

    }, [config.scheduled_date])

    React.useEffect(() => {
        dispatch({
            type: 'setEvent', payload: calendarEvents.map(ev => ({
                title: '',
                start: moment(ev.date).toDate(),
                end: moment(ev.date).toDate(),
                allDay: true,
                status: ev.status,
                click: setDay
            }))
        });
    }, [calendarEvents])

    const changeSelectedDate = (date) => {
        dispatch({ type: 'setDate', payload: moment(date) })
        //dispatch({ type: 'setEvent', payload: [{ ...events[0], start: moment(date).toDate(), end: moment(date).toDate() }] })
        setDay(moment(date));
    }

    return (
        <React.Fragment>
            <div className="separate tar">
                <button className="btn btn-primary sv-btn" onClick={props.Edit}>Editar configuración</button>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <p className="label-title">Chofer asignado</p>
                            <label className="data"> <em>{config.driver.first_name + ' ' + config.driver.last_name} <small>{(!config.prog_id && config.driver.id > 0) && "default"}</small></em> </label>
                        </div>
                        <div className="col-md-6 col-12">
                            <p className="label-title">Vehículo asignado</p>
                            <label className="data"><em>{config.vehicle.name} <small>{(!config.prog_id && config.vehicle.id > 0) && "default"}</small></em></label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-details">
                <div className="row no-pd-lr">

                    <div className="card col-6 ">

                        <div className="calendar-section">
                            <Calendar
                                localizer={localizer}
                                events={events}
                                startAccessor="start"
                                endAccessor="end"
                                messages={messages}
                                views={{ "month": true }}
                                onSelectSlot={(xd) => changeSelectedDate(xd.start)}
                                selectable={true}
                                date={selectedDate.toDate()}
                                components={{ event: MyEvent }}
                            />
                        </div>
                        <div className="card-body">
                            <div className="cont">
                                <div className="bg-indicator bg-red"></div>
                                <label>Fecha sin programación asignada</label>
                            </div>
                            <div className="cont">
                                <div className="bg-indicator bg-yellow"></div>
                                <label>Aún faltan máquinas por visitar</label>
                            </div>
                            <div className="cont">
                                <div className="bg-indicator bg-green"></div>
                                <label>Ruta completada</label>
                            </div>
                        </div>
                    </div>
                    <div className="card col">
                        <div className="light-pd">
                            <h3>Información de ruta del {selectedDate.format("DD MMMM YYYY")}</h3>
                            {
                                (props.config.hasOwnProperty("vehicle_balance") && (moment().format("YYYYMMDD") === moment(config.scheduled_date).format('YYYYMMDD'))) &&
                                <div className="row no-pd-lr separate">
                                    <div className="col-md-4 col-12">
                                        <p className="label-title">$ en transporte</p>
                                        <label>$ {parseFloat(props.config.vehicle_balance).format(2)}</label>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <p className="label-title">$ en mermas</p>
                                        <label>$ {parseFloat(props.config.vehicle_decrease || 0).format(2)}</label>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <p className="label-title">$ en máquinas</p>
                                        <label>$ {parseFloat(props.config.machine_balance || 0).format(2)}</label>
                                    </div>
                                </div>
                            }
                        </div>

                        <hr />
                        <div className="">
                            <div className="light-pd">
                                <h5>Máquinas expendedoras</h5>
                            </div>
                            <Accordion defaultActiveKey="0">
                                <div className="card visit">

                                    <Accordion.Toggle as={Card.Header} eventKey="0">Máquinas por visitar ({notVisited.count})</Accordion.Toggle>

                                    <Accordion.Collapse eventKey="0" className="green">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <Table
                                                    columns={['Máquina', "Opciones"]}
                                                    data={notVisited.rows}
                                                    tableClass='table table-triped'
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                                <div className="card visited">

                                    <Accordion.Toggle as={Card.Header} eventKey="1">Máquinas visitadas ({visited.count})</Accordion.Toggle>

                                    <Accordion.Collapse eventKey="1">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <Table
                                                    columns={['Máquina', "Opciones"]}
                                                    data={visited.rows}
                                                    tableClass='table table-triped'
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default MachinesInRoute;