import React from 'react';
import Table from '../../../General Components/Datatable/table';
import moment from 'moment';
import { apiSetRouteConfig, apiUpdateRouteConfig } from '../../../lib/apiRoutes';
import SearchList from './searchMaquinas';
import EditChoferVehicle from './editChoferVehicle';

//En este componente se genera el objeto para configurar la ruta o editarla
//#config #ruta
const EditMachinesInRoute = (props) => {
    const { NotificationManager } = props;
    const [config, setConfig] = React.useState({
        vehicle_id: 0,
        driver_id: 0,
        scheduled_date: moment().toISOString(),
        machines: []
    });

    React.useEffect(() => {
        if (props.config.vehicle) {
            setConfig({
                ...config,
                vehicle_id: props.config.vehicle.id,
                driver_id: props.config.driver.id,
                scheduled_date: props.config.scheduled_date,
                machines: props.config.machines.map(m => m.machine_id)
            });
        }
        else {
            setConfig({ ...config, scheduled_date: props.config.scheduled_date });
        }
    }, [props.config.vehicle]);

    const selectMachine = (list) => {
        console.log(list);
        setConfig({ ...config, machines: list.rows.map(item => item.id) });
    }

    const SaveRouteSettings = () => {
        //console.log(props.config, config);
        if (config.driver_id > 0) {
            if (config.vehicle_id > 0) {
                if (config.machines.length > 0) {
                    if (props.config.prog_id) {
                        // props.SaveRouteSettings(config, props.config.prog_id);
                        // //edit
                        apiUpdateRouteConfig(props.config.prog_id, config)
                            .then(response => {
                                if (response.error) {
                                    NotificationManager.error('No se ha podido guardar ' + response.message, '¡Error!', 5000);
                                } else {
                                    console.log("modificación config", response);
                                    NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
                                    // props.Edit();
                                    props.SaveRouteSettings(response)
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                NotificationManager.error('No se ha podido guardar', '¡Error!', 4000);
                            })
                    }
                    else {
                        //props.SaveRouteSettings(config);
                        apiSetRouteConfig(props.id, config)
                            .then(response => {
                                if (response.error) {
                                    NotificationManager.error('No se ha podido guardar ' + response.message, '¡Error!', 4000);
                                } else {
                                    console.log("modificación config", response);
                                    NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
                                    // props.Edit();
                                    props.SaveRouteSettings(response)
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                NotificationManager.error('No se ha podido guardar', '¡Error!', 4000);
                            })
                    }
                } else {
                    NotificationManager.error("No ha seleccionado máquinas", "¡Error!", 3000);
                }
            } else {
                NotificationManager.error("No ha seleccionado vehículo", "¡Error!", 3000);
            }
        } else {
            NotificationManager.error("No ha seleccionado chofer", "¡Error!", 3000);
        }

    }

    const setChofer = (chofer) => {
        if (chofer) {
            setConfig({ ...config, driver_id: chofer.id });
        } else {
            setConfig({ ...config, driver_id: 0 });
        }
    }

    const setVehicle = (vehicle) => {
        if (vehicle) {
            setConfig({ ...config, vehicle_id: vehicle.id });
        } else {
            setConfig({ ...config, vehicle_id: 0 });
        }
    }

    return (
        <React.Fragment>
            <EditChoferVehicle
                Edit={props.Edit}
                date={config.scheduled_date}
                setChofer={setChofer}
                setVehicle={setVehicle}
                onSave={SaveRouteSettings}
                config={props.config}
            />

            <div className="section-edit separate card">
                <div className="card-body">
                    <div>
                        <h3>Máquinas expendedoras</h3>
                    </div>
                    <div className="separete">
                        <label className="label-title">Día a configurar </label>
                        <label><em>{' - ' + moment(config.scheduled_date).format("DD/MMMM/YYYY")}</em></label>
                    </div>
                    <SearchList
                        labelKey="name"
                        idKey="id"
                        onSelect={selectMachine}
                        preselectedMachines={props.config.machines.map(m => ({ id: m.machine_id, name: m.name, serial_number: m.serial_number, city_id: m.city_id }))}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default EditMachinesInRoute;