import React from 'react';
import { withRouter } from 'react-router-dom';
import { roles } from '../../lib/constants';
import { apiGetSingleUser } from '../../lib/apiUsers';
import ModalAdd from '../../General Components/Catalogo/modalAdd';
import FormAdd from './usersAdd';

const Details = (props) => {
    const { id } = props.match.params;
    const [user, setUser] = React.useState({ email: '', first_name: '', last_name: '', role_id: 3 });
    const [show, setShow] = React.useState(false);
    const [userToEdit, setUsertoEdit] = React.useState(user);
    const { NotificationManager } = props;

    React.useEffect(() => {
        apiGetSingleUser(id)
            .then(response => {
                if (response.type) {

                }
                else {
                    setUser(response);
                }
            })
    }, [id])


    const handleClose = (obj) => {
        if (obj) {
            setUser(obj);
            NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
        }
        setShow(false);
    }

    const showModal = (obj) => {
        setShow(true);
        setUsertoEdit(obj);
    }

    return (
        <div className="details">
            <div className="row">
                <div className="col-md-8">
                    <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles de usuario</h3>
                </div>
                <div className="col-md-4 tar">
                    <button className="btn btn-danger sv-btn"><i className="far fa-trash-alt" /> Eliminar</button>
                    <button className="btn  btn-primary sv-btn" onClick={() => showModal({ ...user, isEditingPassword: true })}><i className="fas fa-lock" />Cambiar</button>
                    <button className="btn btn-success sv-btn" onClick={() => showModal(user)}><i className="fas fa-edit" /> Editar</button>
                </div>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <p className="label-title">Nombre usuario</p>
                            <label className="data">{user.first_name + ' ' + user.last_name}</label>
                        </div>
                        <div className="col-md-4 col-12">
                            <p className="label-title">{user.email?"Correo electrónico" : "Nombre de usuario"}</p>
                            <label className="data">{user.email || user.username}</label>
                        </div>
                        <div className="col-md-4 col-12">
                            <p className="label-title">Rol</p>
                            <label className="data">{roles.find(rol => rol.id === user.role_id).name}</label>
                        </div>
                    </div>
                </div>
            </div>
            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={userToEdit}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nuevo usuario'}
                titleEdit={'Editar usuario'}
                {...props}
            />
        </div>)
}

export default withRouter(Details);