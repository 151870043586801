import React from 'react';
import { Modal } from 'react-bootstrap';
import { apiGetInventoryMovements, apiGetInventoryWarehouse, apiGetWarehouses } from '../../lib/apiWarehouses';
import { apiGetVehicles, apiGetVehicleMovements } from '../../lib/apiVehicles';
import { apiGetMachines } from '../../lib/apiMachines';
import { apiGetProducts } from '../../lib/apiProducts';
import { AsyncTypeahead, Typeahead } from 'react-bootstrap-typeahead';
import Pagination from '../../General Components/Pagination/pagination';
import { withRouter } from 'react-router-dom';
import SearchList from './searchList';


import './style.scss';

const reducer = (state, action) => {
    switch (action.type) {
        case "setData":
            return { ...state, data: action.payload };
        case 'setSelectedElements':
            return { ...state, selectedElements: action.payload };
        case 'setActivePage':
            return { ...state, activePage: action.payload };
        case 'setMovements':
            return { ...state, movements: action.payload };
        case 'setForm':
            return { ...state, form: action.payload };
        case 'setTypeahead':
            return { ...state, typeahead: action.payload };
        case 'setWarehouseMovement':
            return { ...state, warehouseMovement: action.payload };
        case 'setBuscador':
            return { ...state, buscador: action.payload };
        case 'setFilters':
            return { ...state, filtros: action.payload };
        case 'reset':
            return { ...initialState };
        default:
            return { ...state };
    }
}

const initialState = {
    data: { count: 1, rows: [] },
    selectedElements: [],
    activePage: 1,
    form: { comments: '', type: 0, nameType: '' },
    typeahead: { show: false, labelKey: 'id', isLoading: false, options: [], fun: Promise.resolve() },
    movements: [],
    warehouseMovement: [],
    buscador: { funcToSearch: () => { }, type: '' },
    filtros: { name: '', page: 1, limit: 10 }
};

const modules = [
    { name: "vehiculos", GetMovements: apiGetVehicleMovements },
    { name: "almacenes", GetMovements: apiGetInventoryMovements },
    { name: "maquinas", GetMovements: apiGetInventoryMovements },
]

const ModalMovements = (props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { data, selectedElements, activePage, form, movements, typeahead, warehouseMovement, buscador, filtros } = state;


    const { pathname } = props.location;
    const [, pathProcess] = pathname.split('/');
    const proceso = modules.find(elm => elm.name === pathProcess);

    React.useEffect(() => {
        proceso.GetMovements()
            .then(response => {
                let movement_type_id = props.actionType === "entrada" ? 1 : (props.actionType === "salida" ? 2 : 3);
                let filteredMovements = response.filter(operation => operation.movement_type_id === movement_type_id);
                dispatch({ type: 'setMovements', payload: filteredMovements });
            })
    }, [props.actionType, proceso]);

    const SelectProductToMove = (item) => {
        let exist = false;

        for (let i = 0; i < selectedElements.length; i++) {
            const element = selectedElements[i];
            if (element.id === item.id) {
                exist = true;
            }
        }

        if (!exist) {
            dispatch({ type: "setSelectedElements", payload: [{ ...item, cantidad: 0 }, ...selectedElements] });
        }

        dispatch({ type: "setData", payload: { count: data.count - 1, rows: data.rows.filter(element => element.id !== item.id) } });
    }

    const UnSelectProductToMove = (item) => {
        let exist = false;

        for (let i = 0; i < data.rows.length; i++) {
            const element = data.rows[i];
            if (element.id === item.id) {
                exist = true;
            }
        }

        if (!exist) {
            dispatch({ type: "setData", payload: { count: data.count + 1, rows: [item, ...data.rows] } });
        }

        dispatch({ type: "setSelectedElements", payload: selectedElements.filter(element => element.id !== item.id) });
    }

    const changeProductQuantity = (e, item, stock) => {
        const { value } = e.target;
        let stockQty = value;

        if (stock !== undefined) {
            if (parseInt(value) > stock) {
                stockQty = stock;
            }
        }
        dispatch({
            type: "setSelectedElements", payload: selectedElements.map(element => element.id === item.id ?
                { ...element, cantidad: stockQty } :
                element)
        });
    }

    const handleChangeActivePage = (activePage) => {
        dispatch({ type: 'setActivePage', payload: activePage });
    }
    //  S   W   I   T   C   H 
    const handleChangeSelectMovements = (e) => {
        const { name, value } = e.target;
        let val = parseInt(value), title = '';
        if (val < 7) {
            title = 'Traer desde';
        }
        else {
            if (val < 11) {
                title = 'Enviar a';
            }
            else {
                if (val < 19) {
                    title = 'Traer desde';
                }
                else {
                    if (val < 24) {
                        title = 'Enviar a';
                    }
                }
            }
        }
        switch (value) {
            //get from products 
            case '1':// ALMACEN - ENTRADA - entrada por proveedor
            case '6':// ALMACEN - ENTRADA - Otro tipo de entrada
            case '19':// VEHICULO - ENTRADA - otro tipo de entrada
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: false, title, fun: apiGetProducts } })
                handleFuncToSearch(apiGetProducts, 'apiGetProducts');
                apiGetProducts(filtros)
                    .then(response => {
                        dispatch({ type: 'setData', payload: { ...response, rows: response.rows.map(row => ({ id: row.id, name: row.name, code: row.code })) } })
                    })
                    .catch(console.log)
                break;
            //get from same warehouse
            case '7':// ALMACEN - SALIDA - salida de almacen a proveedor
            case '10'://ALMACEN - SALIDA - otro tipo de salida almacen
            case '11'://ALMACEN - MERMA - producto no encontrado en almacen 
            case '12'://ALMACEN - MERMA - producto dañado/mal estado en almacen
            case '13'://ALMACEN - MERMA - Producto caducado
            case '14'://ALMACEN - MERMA - otro tipo de merma (almacen)
            case '24'://VEHICULO - SALIDA - otro tipo de salida VEHICULO
            case '25'://VEHICULO - MERMA - 
            case '26'://VEHICULO - MERMA - 
            case '27'://VEHICULO - MERMA - 
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: false, title, fun: apiGetProducts } })
                apiGetInventoryWarehouse(props.id, filtros)
                    .then(response => {
                        dispatch({ type: 'setData', payload: response })
                    })
                    .catch(console.log)
                handleFuncToSearch(apiGetInventoryWarehouse, 'apiGetInventoryWarehouse');
                break;
            //get  V E H I C L E S  to select one
            case '2'://ALMACEN - ENTRADA - entrada a almacen por vehiculo
            case '4'://ALMACEN - ENTRADA - entrada a almacen por merma de vehiculo
            case '8'://ALMACEN - SALIDA - salida de almacen a vehiculo
            case '17'://VEHICULO - ENTRADA - traspaso de vehiculo a vehiculo
            case '21'://VEHICULO - SALIDA - traspaso de vehiculo a vehiculo
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: true, title, fun: apiGetVehicles } })
                handleFuncToSearch(apiGetInventoryWarehouse, 'apiGetInventoryWarehouse');
                break;
            //get M A C H I N E S para seleccionar 1
            case '3'://ALMACEN - ENTRADA - entrada a almacen por maquina
            case '9'://ALMACEN - SALIDA - salida de almacen a maquina
            case '16'://VEHICULO - ENTRADA - entrada a vehiculo por maquina
            case '18'://VEHICULO - ENTRADA - entrada a vehiculo por merma de maquina
            case '20': //VEHICULO - SALIDA - salida de vehiculo a máquina
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: true, title, fun: apiGetMachines } })
                handleFuncToSearch(apiGetInventoryWarehouse, 'apiGetInventoryWarehouse');
                break;
            //get from warehouses
            case '15'://VEHICULO - ENTRADA - entrada a vehiculo por almacen
            case '22'://VEHICULO - SALIDA - salida de vehiculo a almacen
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: true, title, fun: apiGetWarehouses } })
                handleFuncToSearch(apiGetInventoryWarehouse, 'apiGetInventoryWarehouse');
                break;
            //get almacenes 
            case '23'://vehiculo - salida - salida de merma de vehiculo a almacen 
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: [], show: true, title, fun: apiGetWarehouses } })
                handleFuncToSearch(apiGetInventoryWarehouse, 'apiGetInventoryWarehouse');
                break;
            default:
                break;
        }
        dispatch({ type: 'setForm', payload: { ...form, [name]: value } });
        dispatch({ type: "setSelectedElements", payload: [] });
    }

    const handleChangeMessage = (e) => {
        const { name, value } = e.target;
        dispatch({ type: 'setForm', payload: { ...form, [name]: value } });
    }

    const handleSubmit = (e) => {
        let obj = {
            operation_type_id: form.type,
            //"warehouse_id": 4,
            comments: form.comments,
            products: selectedElements.map(product => ({
                product_id: product.id,
                quantity: parseInt(product.cantidad, 10)
            }))
        }
        if (warehouseMovement.hasOwnProperty('id')) {
            obj.warehouse_id = warehouseMovement.id;
        }
        props.submit(obj);
        hideModal();
    }

    const typeaheadGetElements = (name) => {
        typeahead.fun({ name, page: 1, limit: 10 })
            .then(response => {
                dispatch({ type: 'setTypeahead', payload: { ...typeahead, options: response.rows } })
            })
            .catch(console.error)
    }

    const handleChangeWarehouseSelected = (search) => {
        if (search.length > 0) {
            dispatch({ type: 'setWarehouseMovement', payload: search[0] })
            let idTosearch = props.id;
            if (props.actionType === "entrada") {
                idTosearch = search[0].id;
            }
            // else {
            //     dispatch({ type: 'setWarehouseMovement', payload: { id: props.id } })
            // }
            let filterFunc = { ...filtros };
            if (form.type === "23") {
                filterFunc.decrease = true;
            }
            apiGetInventoryWarehouse(idTosearch, filterFunc)
                .then(response => {
                    dispatch({ type: 'setData', payload: response })
                })
                .catch()
        }
        else {
            dispatch({ type: 'setWarehouseMovement', payload: [] })
        }
    }

    const hideModal = () => {
        dispatch({ type: 'reset' });
        props.onHide();
    }

    const filterProductsToSelect = (filtros) => {
        if (buscador.type === "apiGetInventoryWarehouse") {
            let idTosear = (props.actionType === "entrada" ? warehouseMovement.id : props.id);
            let filterFunc = { ...filtros };
            if (form.type === "23") {
                filterFunc.decrease = true;
            }
            buscador.funcToSearch(idTosear, filterFunc)
                .then(response => {
                    dispatch({ type: 'setData', payload: response });
                })
        }
        else {
            buscador.funcToSearch(filtros)
                .then(response => {
                    dispatch({ type: 'setData', payload: { ...response, rows: response.rows.map(row => ({ id: row.id, name: row.name, code: row.code, stock: row.stock })) } });
                })
        }
    }

    const handlePagination = (page) => {
        dispatch({ type: 'setFilters', payload: { ...filtros, page } })
        filterProductsToSelect({ ...filtros, page });
    }

    const handleChangeName = (name) => {
        dispatch({ type: 'setFilters', payload: { ...filtros, name } })
        filterProductsToSelect({ ...filtros, name });
    }

    const handleFuncToSearch = (func, type) => {
        dispatch({ type: 'setBuscador', payload: { ...buscador, funcToSearch: func, type } })
    }

    return (<Modal show={props.show} size="lg" keyboard={false} onHide={hideModal}>
        <Modal.Header closeButton><h4>{props.title}</h4></Modal.Header>
        <Modal.Body>
            <div className={activePage === 1 ? "" : 'hide'}>
                <div className="filtros">

                    <div>
                        <label>Tipo de {props.actionType}</label>
                        <select name="type" className="form-control" value={form.type} onChange={handleChangeSelectMovements}>
                            <option value={0}>Seleccione tipo de movimiento</option>
                            {movements.map(movement =>
                                <option key={movement.id} value={movement.id}>{movement.description}</option>
                            )}
                        </select>
                    </div>

                    {
                        typeahead.show &&
                        <div>
                            <label>{typeahead.title}</label>
                            <SearchList
                                labelKey='name'
                                idKey='id'
                                //minLength={5}
                                //onSearch={(query) => typeaheadGetElements(query)}
                                //options={typeahead.options}
                                onSelect={handleChangeWarehouseSelected}
                                functSearch={typeahead.fun}
                            />
                            {/* <AsyncTypeahead
                                id="AsyncTypeahead"
                                labelKey='name'
                                multiple={false}
                                minLength={5}
                                onChange={handleChangeWarehouseSelected}
                                isLoading={typeahead.isLoading}
                                filterBy={['name']}
                                selectHintOnEnter={true}
                                clearButton={false}
                                searchText="Buscando..."
                                options={typeahead.options}
                                onSearch={(query) => typeaheadGetElements(query)}
                                renderMenuItemChildren={(option, props) => (
                                    <div key={option.id}>
                                        {option.name}
                                    </div>)
                                }
                            /> */}
                        </div>
                    }
                    <label>Mensaje</label>
                    <textarea name="comments" value={form.comments} onChange={handleChangeMessage} className="form-control" placeholder="Escriba su comentario"></textarea>
                </div>
                <div className="tac separate">
                    <button className="btn btn-primary sv-btn" onClick={() => handleChangeActivePage(2)}>Siguiente</button>
                </div>
            </div>
            <div className={activePage === 2 ? "" : 'hide'}>

                <div className="products-list">
                    <div className="buscador">
                        <p>Buscar:</p>
                        {/* <Typeahead

                            labelKey="name"
                            options={[]}
                            placeholder="Buscar producto"
                            onChange={setName}
                        /> */}
                        <input className="form-control" value={filtros.name} onChange={(e) => handleChangeName(e.target.value)} />
                    </div>
                    <div className="headers row">
                        <div className="col-md-8"><label>Producto</label></div>
                        <div className="col-md-2"><label>Código</label></div>
                        <div className="col-md-2"><label>Existencia</label></div>
                    </div>
                    <div className="separate">
                        <ul className="list-group">
                            {
                                selectedElements.map(row =>
                                    <li key={row.id} className="list-group-item active" onClick={() => UnSelectProductToMove(row)}>
                                        <div className=" row">
                                            <div className="col-md-1"><i className="fas fa-times" /></div>
                                            <div className="col-md-7"><label>{row.name}</label></div>
                                            <div className="col-md-2"><label>{row.code}</label></div>
                                            <div className="col-md-2"><label>{row.stock} </label></div>
                                        </div>
                                    </li>
                                )
                            }
                            <hr />
                            {
                                data.rows.map(row =>
                                    <li key={row.id} className="list-group-item" onClick={() => SelectProductToMove(row)} >
                                        <div className=" row">
                                            <div className="col-md-1"><i className="fas fa-check" /> </div>
                                            <div className="col-md-7"><label>{row.name}</label></div>
                                            <div className="col-md-2"><label>{row.code}</label></div>
                                            <div className="col-md-2"><label>{row.stock} </label></div>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                    <Pagination
                        total={data.count}
                        pageClick={handlePagination}
                        limit={filtros.limit}
                        page={filtros.page}
                    />
                </div>
                <div className="tac separate">
                    <button className="btn btn-primary sv-btn" onClick={() => handleChangeActivePage(1)}>Atras</button>
                    <button className="btn btn-primary sv-btn" onClick={() => handleChangeActivePage(3)}>Siguiente</button>
                </div>

            </div>
            <div className={activePage === 3 ? "" : 'hide'}>
                <div className="separate">
                    <p>Mensaje: </p>
                    <label>{form.comments}</label>
                </div>
                <div className="headers row">
                    <div className="col-md-6"><label>Producto</label></div>
                    <div className="col-md-3"><label>Existencia</label></div>
                    <div className="col-md-3"><label>Cantidad</label></div>
                </div>
                <ul className="list-group">
                    {
                        selectedElements.map(row =>
                            <li key={row.id} className="list-group-item" >
                                <div className=" row">
                                    <div className="col-md-1" onClick={() => UnSelectProductToMove(row)}><i className="fas fa-times" /></div>
                                    <div className="col-md-5"><label>{row.name}</label></div>
                                    <div className="col-md-3"><label>{row.stock}</label></div>
                                    <div className="col-md-3">
                                        <input type="number" name="cantidad" max={row.stock || 1000000} min={1} className="form-control"
                                            value={row.cantidad} onChange={(e) => changeProductQuantity(e, row, row.stock)} /></div>
                                </div>
                            </li>
                        )
                    }
                </ul>
                <div className="tac separate">
                    <button className="btn btn-primary sv-btn" onClick={() => handleChangeActivePage(2)}>Atrás</button>
                    <button className="btn btn-submit sv-btn" onClick={handleSubmit}>Confirmar</button>
                </div>
            </div>

        </Modal.Body>

    </Modal>)

}

ModalMovements.defaultProps = {
    title: '',
    actionType: '',//merma, entrada, salida
    show: false,
    onHide: () => { },
    submit: () => { }
}

export default withRouter(ModalMovements);
