import { baseUrl, POST } from './constants';

const authUrl = baseUrl + 'auth/';
//region L  O  G  I  N
export const apiSignIn = async (body) => {
    const url = authUrl + "login";

    const request = {
        method: POST,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    };

    return fetch(url, request)
        .then(response => response.json());
};

//endregion


//  F O R G O T   P A S S W O R D  

export const apiForgotPassword = async (body) => {
    const url = authUrl + "mail";

    const request = {
        method: POST,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    };

    return fetch(url, request)
        .then(response => response.json());
};

export const apiResetPassword = async (token, body) => {
    const url = authUrl + "forgot?token=" + token;

    const request = {
        method: POST,
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(body)
    };

    return fetch(url, request)
        .then(response => response.json());
};