import React from 'react';
import { apiGetMachines } from '../../lib/apiMachines';
import Catalogo from '../../General Components/Catalogo/catalogo';
import AddMachine from './addMachine';
import moment from 'moment';
const machinesList = (props) => {
    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    const mapData = (data, edit, details) => {
        let datatable = [];

        datatable = data.map(elm => ({
            name: elm.name,
            lastVisit: moment(elm.updatedAt).format('DD/MMM/YYYY'),
            counter: elm.counter,
            opciones: <React.Fragment>
                <button className="btn btn-edit-table" onClick={() => edit(elm)}><i className="fas fa-edit" /></button>
                <button className="btn btn-primary sv-btn" onClick={() => details(elm)}>Ver más</button>
            </React.Fragment>
        }))

        return datatable;
    }

    return (
        <Catalogo
            module="machines"
            table={{ columns: ["Máquina", 'Última visita', 'Contador', 'Opciones'] }}
            GetData={apiGetMachines}
            title="Administrar Máquinas"
            filters={{ page: 1, limit: 10, city_id: props.city }}
            mapData={mapData}
            modalAdd={{ title: "Crear nueva máquina", titleEdit: "Editar máquina", Form: (props) => <AddMachine {...props} /> }}
            {...props}
        />
    )
}

export default machinesList;