import moment from 'moment';
import jwtDecode from "jwt-decode";
import icon from '../Imgs/favicon.ico';

export const baseUrl = `${process.env.REACT_APP_API_URL}`;
export const wssUrl = `${process.env.REACT_APP_WSS}`;
export const urlAssets = `${process.env.REACT_APP_ASSETS_URL}`;

export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';


export const cities = [
    { id: 1, name: 'Hermosillo' },
    { id: 2, name: 'Nogales' },
    { id: 3, name: 'Navojoa' },
    { id: 4, name: 'Obregón' },
    { id: 5, name: 'Mexicali' },
]

export const roles = [
    { id: 1, name: 'Administrador' },
    { id: 2, name: 'Supervisor' },
    { id: 3, name: 'Chofer' },
]

export const makeUrlGET = (objs) => {
    let params = [];
    for (const key in objs) {
        const val = objs[key];
        if (val) {
            params.push(`${key}=${val}`);
        }
    }
    return params.join('&');
};

export const fetchData = (url, request, resolve, reject) => {
    return fetch(url, request)
        .then(response => {
            if (response.status === 200) {
                resolve(response.json());
            }
            else {
                reject(response);
            }
        })
        .catch(error => {
            reject(error);
        })

}

//region A U T H   R E Q U E S T
export const CreateAuthRequest = async (method, body, isJson = true) => {
    const token = await UpdateToken();
    const request = {
        method,
        headers: new Headers({
            "authorization": `Bearer ${token}`,
            'Content-Type': 'application/json'
        }),
    };
    if (isJson) {
        if (body) {
            request.body = JSON.stringify(body);
        }
    } else {
        request.headers.delete('Content-Type');
        request.body = body;
    }

    return request;
};

//Call to refresh token
const refreshToken = () => {
    let url = baseUrl + 'auth/token';
    let headers = new Headers();
    headers.append("authorization", `Bearer ${localStorage.token}`);
    let request = { method: POST, headers }
    return fetch(url, request)
        .then(response => response.json())
        .then(response => response.token)
};

//Update token before it expires
export const UpdateToken = async () => {

    if (localStorage.token && localStorage.token !== "undefined") {
        const currentTime = moment().unix();
        const decoded = jwtDecode(localStorage.token);
        if (decoded.exp < currentTime) {
            const currentToken = await refreshToken();
            localStorage.setItem("token", currentToken);
            return localStorage.getItem("token");
        }
        else {
            return localStorage.getItem("token");
        }
    }
    else {
        localStorage.removeItem("token");
        return null;
    }
};


//region Notifications
export let permissionNotification = Notification.permission === "granted";

export const AskNotificationPermission = () => {
    Notification.requestPermission(function (permission) {
        if (permission === "granted") {
            let n = new Notification("Sonora Vending", { body: "Ahora recibirás notificaciones" });
            setTimeout(n.close.bind(n), 5000);
        }
    })
}

export const showNotification = (title, thebody) => {
    console.log(title, thebody);
    let options = {
        body: thebody,
        icon
    }
    let n = new Notification(title, options);
    n.onclick = function () { window.location.href = "/alertas" }
    //setTimeout(n.close.bind(n), 5000);
}
