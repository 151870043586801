import React from 'react';
import { apiNewVehicle, apiUpdateVehicle } from '../../lib/apiVehicles';

const AddVehicle = (props) => {
    const [vehicle, setVehicle] = React.useState({
        name: '',
        city_id: props.city,
        license_plate: ''
    });
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
                setVehicle(props.editElement);
            }
        }
    }, [props.editElement])

    const submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let obj = vehicle;

            if (isEditing) {
                props.showLoader(true);
                apiUpdateVehicle(props.editElement.id, obj)
                    .then(response => {
                        if (response.type) {
                            props.handleClose();
                        }
                        else {
                            props.handleClose(obj);
                        }
                        props.showLoader(false);
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
            else {
                props.showLoader(true);
                apiNewVehicle(obj)
                    .then(response => {
                        //Redireccionar a pagina de detalle
                        props.history.push('/vehiculos/' + response.id);
                        props.handleClose(obj);
                        props.showLoader(false);
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
        }
    }

    const changeForm = (e) => {
        const { name, value } = e.target;
        setVehicle({ ...vehicle, [name]: value });
    }

    return (
        <form onSubmit={submit}>
            <div className="form-group">
                <label className="label-title">Nombre</label>
                <input name="name" className="form-control" placeholder="Escribe el nombre del vehículo" value={vehicle.name} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Placas</label>
                <input name="license_plate" placeholder="Ingrese las placas del vehículo" className="form-control" value={vehicle.license_plate} onChange={changeForm} required />
            </div>
            <div className="tac">
                <button type="submit" className="btn btn-submit sv-btn" >Guardar</button>
            </div>
        </form>
    )
}

export default AddVehicle;