import React from 'react';
import Table from '../Datatable/table';
import Pagination from '../Pagination/pagination';
import moment from 'moment';
import { apiGetLogsWarehouse } from '../../lib/apiWarehouses';
import { apiGetLogsProduct } from '../../lib/apiProducts';

const LogsTable = (props) => {
    const { id, setUpdateLogs, updateLogs, isProduct } = props;
    const [filters, setFilters] = React.useState({ page: 1, limit: 10 });
    const [data, setData] = React.useState({ count: 10, rows: [] });

    React.useEffect(() => {
        if (updateLogs) {
            
            if (isProduct) {
                apiGetLogsProduct(id, filters)
                    .then(response => {
                        if (response.type) {

                        }
                        else {
                            setData(response);
                        }
                        setUpdateLogs(false);
                    })
                    .catch(error => {
                        setUpdateLogs(false);
                    })
            }
            else {
                apiGetLogsWarehouse(id, filters)
                    .then(response => {
                        if (response.type) {

                        }
                        else {
                            setData(response);
                        }
                        setUpdateLogs(false);
                    })
                    .catch(error => {
                        setUpdateLogs(false);
                    })
            }
        }

    }, [filters, id, updateLogs, setUpdateLogs, isProduct])

    const changePage = (page) => {
        setFilters({ ...filters, page });
    }

    return (
        <div className="card separate">
            <div className="card-body">
                <h3>Logs</h3>
                <div className="table-responsive">
                    <Table
                        columns={['Fecha y hora', "Descripción"]}
                        data={data.rows}//.map(row => ({ createdAt: moment(row.createdAt).format("DD/MMM/YYYY hh:mm:ss a"), description: row.description }))}
                        tableClass='table table-striped'
                        configTable={{
                            columnDefinition: { createdAt: (createdAt) => moment(createdAt).format("DD/MMMM/YYYY hh:mm:ss a") },
                            columnStyle: { createdAt: { width: '22%' } }
                        }}
                    />
                </div>
                <Pagination
                    total={data.count}
                    page={filters.page}
                    limit={filters.limit}
                    pageClick={changePage}
                />
            </div>
        </div>
    )
}

LogsTable.defaultProps = {
    GetData: () => (Promise.resolve(() => ({ count: 0, rows: [] }))),
    updateLogs: true,
    setUpdateLogs: () => { },
    isProduct: false
}

export default LogsTable;