import React from 'react';
import jwtDecode from "jwt-decode";
import { NavLink, withRouter } from 'react-router-dom';


const linksSideBar = [
    { link: '/monitor', class: '', name: "Monitor", icon: 'fas fa-lg fa-home', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/rutas', class: '', name: "Rutas", icon: 'fas fa-lg fa-route', permissions: ['ADMIN'] },
    { link: '/maquinas', class: '', name: "Máquinas", icon: 'fas fa-lg fa-building', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/vehiculos', class: '', name: "Vehículos", icon: 'fas fa-lg fa-truck', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/productos', class: '', name: "Productos", icon: 'fas fa-lg fa-book-open', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/reportes', class: '', name: "Reportes", icon: 'far fa-lg fa-file-alt', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/usuarios', class: '', name: "Usuarios", icon: 'fas fa-lg fa-user', permissions: ['ADMIN', 'BUSINESS'] },
    { link: '/almacenes', class: '', name: "Almacenes", icon: 'fas fa-lg fa-warehouse', permissions: ['ADMIN', 'BUSINESS'] },
    //{ link: '/alertas', class: '', name: "Alertas", icon: 'fas fa-lg fa-bell', permissions: ['ADMIN', 'BUSINESS'] },
];



const SideBar = (props) => {
    const { notificationCounter } = props;
    // function toggle() {
    //     var element = document.getElementById("wrapper");

    //     if (element.classList.contains("toggled")) {
    //         element.classList.remove("toggled");
    //     } else {
    //         element.classList.add("toggled");
    //     }
    // };

    //const decoded = jwtDecode(localStorage.token);

    //const availableLinks = linksSideBar.filter(elm => elm.permissions.includes(decoded.type))

    const formatCounter = (num) => {
        if (num > 9) {
            return '+9';
        }
        else {
            return num;
        }
    }

    const clickNotifications = () => {
        //props.setGetNotifications(true)
    }

    return <div id="sidebar-wrapper">
        <ul className="sidebar-nav">
            {
                // availableLinks
                linksSideBar.map(elm =>
                    <li key={elm.link}>
                        <NavLink to={elm.link} title={elm.name} className={elm.class}><i className={elm.icon} />  {elm.name}</NavLink>
                    </li>
                )
            }
            <li onClick={clickNotifications}>
                <NavLink to='/alertas' title="Alertas" className='' ><i className='fas fa-lg fa-bell ' /> {' Alertas '}
                    {
                        notificationCounter > 0 &&
                        <span className="badge badge-danger"><small>{formatCounter(notificationCounter)}</small></span>
                    }
                </NavLink>
            </li>
        </ul>
    </div>

};



export default withRouter(SideBar);


