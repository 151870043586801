import React from 'react';
import { withRouter } from 'react-router-dom';
import { apiGetSingleWarehouse, apiGetLogsWarehouse, apiGetInventoryWarehouse } from '../../lib/apiWarehouses';
import ModalAdd from '../../General Components/Catalogo/modalAdd';
import FormAdd from './addWarehouse';
//import { NotificationContainer, NotificationManager } from 'react-notifications';
import moment from 'moment';
import LogsTable from '../../General Components/LogsTable/logsTable';
import Inventory from '../../General Components/Inventory/inventory';
import CodePreview from '../../General Components/CodePreview/codePreview';

const reducer = (state, action) => {
    switch (action.type) {
        case "setWarehouse":
            return { ...state, warehouse: action.payload };
        case "setShow":
            return { ...state, show: action.payload }
        case "setUpdateLogs":
            return { ...state, updateLogs: action.payload }
        case "setUpdateCode":
            return { ...state, shouldUpdateCode: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    warehouse: { email: '', name: '', last_name: '', role_id: 3 },
    show: false,
    updateLogs: true,
    shouldUpdateCode: true
};

const Details = (props) => {
    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { warehouse, show, updateLogs, shouldUpdateCode } = state;

    React.useEffect(() => {
        apiGetSingleWarehouse(id)
            .then(response => {
                if (response.type) {

                }
                else {
                    dispatch({ type: 'setWarehouse', payload: response });
                }
            })
    }, [id])


    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    const handleClose = (obj, type, response) => {
        if (type) {
            if (type === "error") {
                NotificationManager.error('No se ha podido guardar. ' + response.message, 'Ocurrió un error', 7000);
            }
            else {
                dispatch({ type: 'setWarehouse', payload: obj })
                NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
                dispatch({ type: 'setUpdateCode', payload: true });
                dispatch({ type: 'setShow', payload: false });
            }
        }
        else {
            dispatch({ type: 'setShow', payload: false });
        }
    }

    const showModal = (obj) => {
        dispatch({ type: 'setShow', payload: true });
    }

    const setUpdateLogs = (payload) => {
        dispatch({ type: 'setUpdateLogs', payload })
    }

    const setShouldUpdateCode = (status) => {
        dispatch({ type: 'setUpdateCode', payload: status })
    }

    return (
        <div className="details">
            <div className="row">
                <div className="col-md-6">
                    <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles de almacén</h3>
                </div>
                <div className="col-md-6 tar">
                    <button className="btn btn-success sv-btn" onClick={() => showModal(warehouse)}><i className="fas fa-edit" /> Editar</button>
                </div>
            </div>
            <div className="card separate">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <p className="label-title">Nombre almacén</p>
                            <label className="data">{warehouse.name}</label>
                        </div>
                        <div className="col-md-6 col-12">
                            <p className="label-title">Última actualización</p>
                            <label className="data">{moment(warehouse.updatedAt).format('DD/MMM/YYYY hh:mm a')}</label>
                        </div>
                    </div>
                </div>
            </div>

            <CodePreview id={id}
                shouldUpdate={shouldUpdateCode}
                setShouldUpdate={setShouldUpdateCode}
            />

            <Inventory
                GetData={apiGetInventoryWarehouse}
                id={id}
                setUpdateLogs={setUpdateLogs}
                NotificationManager={NotificationManager}
            />


            <LogsTable
                GetData={apiGetLogsWarehouse}
                id={id}
                updateLogs={updateLogs}
                setUpdateLogs={setUpdateLogs}
            />

            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={warehouse}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nuevo almacén'}
                titleEdit={'Editar almacén'}
                {...props}
            />

            {/* <NotificationContainer /> */}
        </div>)
}

export default withRouter(Details);