import React from 'react';
import moment from 'moment';
import Table from '../../General Components/Datatable/table';
import { apiGetStatistics } from '../../lib/apiMonitor';
import './monitor.scss';

moment.updateLocale('es', {
    monthsShort: [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
    ],
    months: [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ],
    weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
    weekdaysShort: 'Dom_Lun_Mar_Mié_Jue_Vie_Sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    weekdaysParseExact: true,
});

const date = moment().toISOString();

const Monitor = (props) => {
    const { city, NotificationManager } = props;

    const [state, setState] = React.useState({
        visited_machines: 0,
        non_visited_machines: 0,
        machines_percentage: 0,
        machine_balance: '',
        vehicle_balance: '',
        finished_routes: 0,
        non_finished_routes: 0,
        machines: []
    });

    React.useEffect(() => {
        apiGetStatistics(city, date)
            .then(response => {
                if (response.error) {
                    NotificationManager.error("No se han podido obtener los datos. Inténtelo de nuevo mas tarde. " + response.message, "¡Error!", 5000);
                } else {
                    console.log("monitor", response);
                    setState(response);
                }
            })
            .catch(error => {
                console.error(error);
                NotificationManager.error("No se han podido obtener los datos. Inténtelo de nuevo mas tarde. ", "¡Error!", 5000);
            })
    }, [city, NotificationManager]);

    return <div className="monitor">

        <div className="separate greatings">
            <div className="row">
                <div className="col-md-6">
                    <h5>Bienvenido, {props.user.first_name} {props.user.last_name}</h5>
                </div>
                <div className="col-md-6 tar">
                    <h5>{moment().format("dddd, DD/MMM/YYYY")}</h5>
                </div>
            </div>
        </div>

        <div className="separate statistics">
            <div className="card">
                <div className="card-body">
                    <h5>Estadísticas</h5>
                    <div className="separate row">
                        <div className="col ">
                            <h5><label className="color-blue"><i className="far fa-square" /></label> Máquinas</h5>
                            <div className="row">
                                <div className="col">
                                    <h6>Visitadas</h6>
                                    <label>{state.visited_machines}</label>
                                </div>
                                <div className="col">
                                    <h6>Por visitar</h6>
                                    <label>{state.non_visited_machines}</label>
                                </div>
                                <div className="col brd-right xd">
                                    <h6>% avance</h6>
                                    <label className="color-blue">{state.machines_percentage} %</label>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <h5><label className="color-blue"><i className="fas fa-dollar-sign" /></label> Productos</h5>
                            <div className="row">
                                <div className="col">
                                    <h6>monto en máquinas</h6>
                                    <label>$ {parseFloat(state.machine_balance || 0).format(2)} <small>MXN</small></label>
                                </div>
                                <div className="col">
                                    <h6>monto en vehículos</h6>
                                    <label>$ {parseFloat(state.vehicle_balance || 0).format(2)} <small>MXN</small></label>
                                </div>
                            </div>
                        </div>
                        <div className="col ">
                            <h5><label className="color-blue"><i className="fas fa-route" /></label> Rutas</h5>
                            <div className="row">
                                <div className="col brd-left">
                                    <h6>Completadas</h6>
                                    <label>{state.finished_routes}</label>
                                </div>
                                <div className="col">
                                    <h6>En proceso</h6>
                                    <label>{state.non_finished_routes}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div className="machines-list">
            <div className="card">
                <div className="card-header">
                    <h5>Lista de maquinas expendedoras</h5>
                </div>
                <div className="card-body">
                    <Table
                        columns={["ID", 'Nombre', 'Ruta', 'Ciudad', 'Chofer', 'Monto']}
                        data={state.machines.map(m => ({ ...m, balance: '$' + parseFloat(m.balance).format(2) }))}
                    />

                </div>
            </div>

        </div>

        {/* <div className="separate routes-map">
            <div className="card">
                <div className="card-header">
                    <h5>Mapa de rutas</h5>
                </div>
                <div className="card-body">


                </div>
            </div>
        </div> */}
    </div>
}


export default Monitor;