import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'warehouse/';

export const apiGetWarehouses = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleWarehouse = async (id) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewWarehouse = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateWarehouse = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetLogsWarehouse = async (id, obj) => {
    let url = `${urlAPI}${id}/log?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetInventoryWarehouse = async (id, obj) => {
    let url = `${urlAPI}${id}?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetInventoryMovements = async () => {
    let url = `${urlAPI}operation`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiRegisterInventoryMovement = async (id, obj) => {
    let url = urlAPI + id;
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGenerateBarCode = async (id) => {
    let url = urlAPI + id+'/barcode';
    const request = await CreateAuthRequest(GET, null, false);
    return fetch(url, request)
        .then(response => response.blob())
}
