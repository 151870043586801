import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { NavDropdown, Nav } from 'react-bootstrap';
import img_logo from "../../Imgs/logo-mmnt.png";
import { apiGetCities } from "../../lib/apiCities";

const TopBar = (props) => {

    const { user, city } = props;
    const [cities, setCities] = React.useState([]);

    React.useEffect(() => {
        apiGetCities()
            .then(response => {
                setCities(response);
            })
            .catch(error => {
                console.error("ERROR en getCITIES", error);
            })
    }, []);

    const signOut = () => {
        localStorage.clear();
        window.location.href = "/"
    }

    const changeCity = (e) => {
        props.setCity(e.target.value);
    }

    return (
        <nav className="navbar fixed-top navbar-light bg-white navbar-expand-lg ">
            <Link className="navbar-brand" to='/'>
                <img src={img_logo} alt="Logo Proimagen" style={{ height: '30px' }} />
            </Link>
            <Nav className="mr-auto">
                <select className="select-city" value={city} onChange={changeCity}>
                    {
                        cities.map(city =>
                            <option key={city.id} value={city.id}>{city.name}</option>
                        )
                    }
                </select>
            </Nav>
            <ul className="navbar-nav ml-auto nav-flex-icons" >
                <NavDropdown title={<Fragment><i className="fas fa-user-circle" /> {user.first_name} {user.last_name}</Fragment>} id="basic-nav-dropdown">
                    <NavDropdown.Item href="" onClick={signOut}>Cerrar sesión</NavDropdown.Item>
                </NavDropdown>
            </ul>
        </nav >)
}

export default TopBar;
