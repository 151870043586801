import React from 'react';
import { apiGetRoutes } from '../../lib/apiRoutes';
import Catalogo from '../../General Components/Catalogo/catalogo';
import AddRoute from './addRoute';
import { Link } from 'react-router-dom';

const routesList = (props) => {

    const mapData = (data, edit, details) => {
        let datatable = [];

        datatable = data.map(elm => ({
            name: elm.name,
            driver: elm.assigned_driver,
            machine_count: elm.machine_count,
            opciones: <React.Fragment>
                <button className="btn btn-edit-table" onClick={() => edit(elm)}><i className="fas fa-edit" /></button>
                <button className="btn btn-primary sv-btn" onClick={() => details(elm)}>Ver más</button></React.Fragment>
        }))

        return datatable;
    }

    return (
        <Catalogo
            module="routes"
            table={{ columns: ["Ruta", 'Chofer asignado', 'Máquinas a visitar', 'Opciones'] }}
            GetData={apiGetRoutes}
            title="Administrar Rutas"
            filters={{ page: 1, limit: 10, city_id: props.city }}
            mapData={mapData}
            modalAdd={{ title: "Crear nueva ruta", titleEdit: "Editar ruta", Form: (props) => <AddRoute {...props} /> }}
            {...props}
        />
    )
}

export default routesList;