import React from 'react';
import { apiGetWarehouses } from '../../lib/apiWarehouses';
import Catalogo from '../../General Components/Catalogo/catalogo';
import AddWarehouse from './addWarehouse';
import { Link, withRouter } from 'react-router-dom';

const Warehouses = (props) => {

    const mapData = (data, edit, details) => {
        let datatable = [];

        datatable = data.map(elm => ({
            name: elm.name,
            opciones: <React.Fragment>
                <button className="btn btn-edit-table" onClick={() => edit(elm)}><i className="fas fa-edit" /></button>
                <button className="btn btn-primary sv-btn" onClick={() => details(elm)}>Ver más</button></React.Fragment>
        }))

        return datatable;
    }


    return (
        <Catalogo
            module="warehouses"
            table={{ columns: ["Nombre", 'Opciones'] }}
            GetData={apiGetWarehouses}
            title="Administrar Almacenes"
            filters={{ page: 1, limit: 10, city_id: props.city }}
            mapData={mapData}
            modalAdd={{ title: "Crear nuevo almacén", titleEdit: "Editar almacén", Form: (props) => <AddWarehouse {...props} /> }}
            {...props}
        />
    )
}

export default withRouter(Warehouses);