import React from 'react';
import { apiGetVehicles } from '../../lib/apiVehicles';
import Catalogo from '../../General Components/Catalogo/catalogo';
import AddVehicle from './addVehicle';
import { Link } from 'react-router-dom';

const vehiclesList = (props) => {

    const mapData = (data, edit, details) => {
        let datatable = [];

        datatable = data.map(elm => ({
            name: elm.name,
            license_plate: elm.license_plate,
            opciones: <React.Fragment>
                <button className="btn btn-edit-table" onClick={() => edit(elm)}><i className="fas fa-edit" /></button>
                <button className="btn btn-primary sv-btn" onClick={() => details(elm)}>Ver más</button>
            </React.Fragment>

        }))

        return datatable;
    }

    return (
        <Catalogo
            module="vehicles"
            table={{ columns: ["Vehículo", 'Placas', 'Opciones'] }}
            GetData={apiGetVehicles}
            title="Administrar Vehículos"
            filters={{ page: 1, limit: 10, city_id: props.city }}
            mapData={mapData}
            modalAdd={{ title: "Crear nuevo vehículo", titleEdit: "Editar vehículo", Form: (props) => <AddVehicle {...props} /> }}
            {...props}
        />
    )
}

export default vehiclesList;