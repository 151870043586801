import React from 'react';
import useDebounce from '../../../General Components/CustomHooks/useDebounce';
import VirtualizedList from './virtualizedList';
import { apiGetMachines } from '../../../lib/apiMachines';
import { List, AutoSizer } from 'react-virtualized';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setResults':
            return { ...state, results: action.payload }
        case 'setSearch':
            return { ...state, search: action.payload }
        case 'setItemSelected':
            return { ...state, selectedItem: action.payload }
        case 'setFilters':
            return { ...state, filters: action.payload }
        case 'setHasNextPage':
            return { ...state, hasNextPage: action.payload }
        case 'setIsNextPageLoading':
            return { ...state, isNextPageLoading: action.payload }
        case 'setSelectedData':
            return { ...state, selectedData: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    selectedItem: { id: 0 },
    search: '',
    results: { count: 0, rows: [] },
    filters: { page: 1, limit: 10 },
    hasNextPage: false,
    isNextPageLoading: false,
    selectedData: { count: 0, rows: [] }
};

const SearchList = (props) => {
    const { onSelect, labelKey, idKey, preselectedMachines } = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const { search, results, filters, hasNextPage, isNextPageLoading, selectedData } = state;

    const debouncedValue = useDebounce(search, 300);
    const [lastSearch, setLastSearch] = React.useState('');

    React.useEffect(() => {
        loadNextPage();
    }, []);

    React.useEffect(() => {
        if (debouncedValue !== lastSearch) {
            apiGetMachines({ name: debouncedValue, page: 1, limit: 10 })
                .then(response => {
                    if (response.error) {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                    }
                    else {
                        dispatch({ type: 'setIsNextPageLoading', payload: false });
                        dispatch({ type: 'setResults', payload: { count: response.count, rows: response.rows } })
                        const pages = response.count / 10;

                        dispatch({ type: 'setHasNextPage', payload: 1 < pages });
                    }
                })
                .catch((error) => {
                    dispatch({ type: 'setIsNextPageLoading', payload: true });
                    console.error()
                })
            dispatch({ type: 'setFilters', payload: { limit: 10, page: 1 } });
            setLastSearch(debouncedValue);
        }
    }, [debouncedValue, lastSearch])

    React.useEffect(() => {
        if (results.rows.length > 0 && preselectedMachines.length > 0 && selectedData.count === 0) {
            dispatch({
                type: 'setSelectedData',
                payload: {
                    count: preselectedMachines.length,
                    rows: preselectedMachines.map(x => results.rows.find(r => r.id === x.id) || x),
                }
            })
            // dispatch({ type: 'setSelectedData', payload: { count: preselectedMachines.length, rows: preselectedMachines } });
            //dispatch({ type: '', payload: { count: 0, rows: [] } });
        }
    }, [results.rows, preselectedMachines]);

    const handleChangeSearch = (e) => {
        dispatch({ type: 'setSearch', payload: e.target.value });
    }

    const loadNextPage = () => {
        
        dispatch({ type: 'setIsNextPageLoading', payload: true });
        apiGetMachines({ name: debouncedValue, ...filters })
            .then(response => {
                if (response.error) {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                }
                else {
                    dispatch({ type: 'setIsNextPageLoading', payload: false });
                    dispatch({ type: 'setResults', payload: { count: response.count, rows: [...results.rows, ...response.rows] } })
                    const pages = response.count / filters.limit;
                    
                    dispatch({ type: 'setHasNextPage', payload: filters.page < pages });
                }
            })
            .catch((error) => {
                dispatch({ type: 'setIsNextPageLoading', payload: true });
                console.error()
            })
        dispatch({ type: 'setFilters', payload: { ...filters, page: filters.page + 1 } });
    }

    const loadContent = (item) => {
        return <React.Fragment >
            <td className="col-md-4">{item[labelKey]}</td>
            <td className="col-md-4">{item.city_id}</td>
            <td className="col-md-4"><button className="btn btn-primary btn-sm" onClick={() => selectMachine(item)}><i className="fas fa-arrow-right" /></button></td>
        </React.Fragment>

    }

    const selectMachine = (item) => {
        
        if (!selectedData.rows.find(elm => elm.id === item.id)) {
            let payload = { count: selectedData.count + 1, rows: [item, ...selectedData.rows] };
            onSelect(payload);
            dispatch({ type: 'setSelectedData', payload });
            dispatch({ type: 'setResults', payload: { count: results.count - 1, rows: results.rows.filter(elm => elm.id !== item.id) } })
        }
        else {
            dispatch({ type: 'setResults', payload: { count: results.count - 1, rows: results.rows.filter(elm => elm.id !== item.id) } })
        }
    }

    const unSelectMachine = (item) => {
        const payload = { count: selectedData.count - 1, rows: selectedData.rows.filter(elm => elm.id !== item.id) };
        onSelect(payload);
        dispatch({ type: 'setSelectedData', payload });
        if (!results.rows.find(elm => elm.id === item.id)) {
            dispatch({ type: 'setResults', payload: { count: results.count + 1, rows: [item, ...results.rows] } });
        }
    }

    function rowRenderer({
        key,         // Unique key within array of rows
        index,       // Index of row within collection
        isScrolling, // The List is currently being scrolled
        isVisible,   // This row is visible within the List (eg it is not an overscanned row)
        style        // Style object to be applied to row (to position it)
    }) {
        return (
            <tr key={key} style={style} className="row no-pd-lr">
                <td className="col-md-4">
                    <button className="btn btn-primary btn-sm" onClick={() => unSelectMachine(selectedData.rows[index])}><i className="fas fa-arrow-left" /></button>
                </td>
                <td className="col-md-4">
                    {selectedData.rows[index].name}
                </td>
                <td className="col-md-4">
                    ciudad
                </td>
            </tr>
        )
    }
    //console.log(results, hasNextPage);

    return (<div>
        <div className="row">
            <div className="col-md-4" style={{ marginBottom: ".5rem" }}>
                <input placeholder="Buscar máquina expendedora" className="form-control" value={search} onChange={handleChangeSearch} />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <h5>Lista de máquinas expendedoras</h5>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Máquina</th>
                            <th>Ciudad</th>
                            <th>Seleccionar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {VirtualizedList({ hasNextPage, isNextPageLoading, list: results.rows, loadNextPage, loadContent })}
                    </tbody>
                </table>
            </div>
            <div className="col">
                <h5>Máquina expendedoras seleccionada</h5>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Quitar</th>
                            <th>Máquina</th>
                            <th>Ciudad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AutoSizer disableHeight>
                            {({ height, width }) => (
                                <List
                                    width={width}
                                    height={200}
                                    rowCount={selectedData.count}
                                    rowHeight={50}
                                    rowRenderer={rowRenderer}
                                />
                            )}
                        </AutoSizer>
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}

export default SearchList;
