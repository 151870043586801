import React, { useEffect, useState } from 'react';
import Table from '../../General Components/Datatable/table';
import Paginations from '../../General Components/Pagination/pagination';
import loaderConnect from '../Loader/loaderConnect';
import ModalAdd from './modalAdd';
import moment from 'moment';

const reducer = (state, action) => {
    switch (action.type) {
        case "setDataList":
            return { ...state, dataList: action.payload }
        case 'setFilters':
            return { ...state, filters: action.payload }
        case 'setShow':
            return { ...state, show: action.payload }
        case 'setEditElement':
            return { ...state, editElement: action.payload }
        case 'setSearch':
            return { ...state, search: action.payload }
        case 'setPermissions':
            return { ...state, permissions: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    dataList: { count: 0, rows: [] },
    filters: { name: '', page: 1, limit: 10, city_id: localStorage.city_id },
    show: false,
    editElement: null,
    permissions: false
};

const Catalogo = (props) => {

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [filters, setFilters] = React.useState(props.location.state || { ...initialState.filters, city_id: props.city });

    const { dataList, show, editElement, permissions } = state;
    const { GetData, showLoader, city, NotificationManager } = props;

    useEffect(() => {
        setFilters({ ...filters, city_id: parseInt(city, 10) })
    }, [city])

    useEffect(() => {
        showLoader(true);
        let filtros = filters;
        if (props.module === "routes") {
            filtros = { ...filters, date: moment().toISOString() }
        }
        GetData(filtros)
            .then(response => {
                if (response.error) {
                    //NotificationManager.error(response.message, 'Error :(', 6000);
                    if (response.message === "No tienes permisos para acceder a este recurso.") {
                        dispatch({ type: 'setPermissions', payload: false });
                    }
                    else {
                        dispatch({ type: 'setPermissions', payload: true });
                    }
                }
                else {
                    dispatch({ type: 'setDataList', payload: response });
                    dispatch({ type: 'setPermissions', payload: true });
                }
                showLoader(false);
            })
            .catch(error => {
                showLoader(false);
            })
    }, [filters, GetData, showLoader, props.location.state])



    const paginationHandleClick = (page) => {
        setFilters({ ...filters, page });
        const location = {
            pathname: `${props.location.pathname}`,
            state: { ...filters, page }
        }
        props.history.replace(location);
    }

    const newElementHandleClick = () => {
        dispatch({ type: 'setShow', payload: true });
    }

    const mapData = (data) => {
        return props.mapData(data, btnEditHandleClick, btnDetailsHandleClick);
    }

    const handleClose = (obj, type, response) => {
        if (type) {
            if (type === "error") {
                NotificationManager.error('No se ha podido guardar. ' + response.message, 'Ocurrió un error', 4000);
            }
            else {
                dispatch({ type: 'setDataList', payload: { ...dataList, rows: dataList.rows.map(elm => elm.id === obj.id ? obj : elm) } });
                NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
                dispatch({ type: 'setShow', payload: false });
                dispatch({ type: 'setEditElement', payload: null });
            }
        }
        else {
            if (obj) {
                dispatch({ type: 'setDataList', payload: { ...dataList, rows: dataList.rows.map(elm => elm.id === obj.id ? obj : elm) } });
                NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
            }
            dispatch({ type: 'setShow', payload: false });
            dispatch({ type: 'setEditElement', payload: null });
        }
    }

    const btnEditHandleClick = (elm) => {
        console.log("clickedit", elm);
        dispatch({ type: 'setEditElement', payload: elm });
        dispatch({ type: 'setShow', payload: true });
    }

    const btnDetailsHandleClick = (item) => {

        const location = {
            pathname: `${props.location.pathname}`,
            state: filters
        }
        props.history.replace(location);
        props.history.push(`${props.location.pathname}/${item.id}`);
    }

    const SearchChange = (e) => {
        setFilters({ ...filters, name: e.target.value, page: 1 });
    }

    const limitChange = (limit) => {
        setFilters({ ...filters, page: 1, limit });
    }

    return permissions ?
        <div>
            <div className="row">
                <div className="col-6">
                    <h3 >{props.title}</h3>
                </div>
                <div className="col-6 tar">
                    <button className="btn  btn-primary sv-btn" onClick={newElementHandleClick}><i className="fas fa-plus" /> Agregar</button>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="filters row">
                        <div className="col-md-4">
                            <input name="search" placeholder='Buscar por nombre' className="form-control" value={filters.name} onChange={SearchChange} />
                        </div>

                    </div>
                    <Table
                        columns={props.table.columns}
                        data={mapData(dataList.rows)}
                        tableClass='table table-striped'
                    />
                    <Paginations
                        total={dataList.count}
                        page={filters.page}
                        limit={filters.limit}
                        pageClick={paginationHandleClick}
                        showLimit={true}
                        limitOption={[10, 25, 50, 100]}
                        limitChange={limitChange}
                    />
                </div>
            </div>

            <ModalAdd
                editElement={editElement}
                show={show}
                city={props.filters.city_id}
                handleClose={handleClose}
                component={(propss) => <props.modalAdd.Form {...propss} />}
                title={props.modalAdd.title}
                titleEdit={props.modalAdd.titleEdit}
                showLoader={props.showLoader}
                {...props}
            />
        </div >
        :
        <div className="alert alert-danger">No tienes permisos para acceder a este recurso</div>


}

Catalogo.defaultProps = {
    module: "default",
    GetData: Promise.resolve(),
    title: 'Title',
    filters: { page: 1, limit: 10 },
    newHandleClick: () => { },
    table: { columns: [], data: [] }
}

export default loaderConnect(Catalogo);

