import React from "react";
import SearchList from "./searchMaquinas";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { apiGetDrivers } from "../../../lib/apiUsers";
import { apiGetVehicles } from "../../../lib/apiVehicles";
import { NotificationManager } from "react-notifications";
import { apiSetDefaultRouteConfig } from "../../../lib/apiRoutes";
import moment from "moment";

const days = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];

const DefaultWeek = ({ programacion, config, afterSave }) => {
  /** ********************************** */
  /** Days */
  /** ********************************** */

  const [day, setDay] = React.useState(() => {
    return programacion
      ? programacion[0]
      : {
          id: 0,
          day_of_week: 1,
          machines: [],
          user_id: null,
          vehicle_id: null,
        };
  });

  const selectMachine = (list) => {
    setDay((prev) => ({
      ...prev,
      machines: list.rows.map((item) => item.id),
    }));
  };

  const saveRoute = () => {
    if (!day.user_id) {
      NotificationManager.error("No ha seleccionado chofer", "¡Error!", 3000);
      return;
    }
    if (!day.vehicle_id) {
      NotificationManager.error("No ha seleccionado chofer", "¡Error!", 3000);
      return;
    }
    if (!day.machines.length) {
      NotificationManager.error("No ha seleccionado máquinas", "¡Error!", 3000);
      return;
    }
    apiSetDefaultRouteConfig(day.id, {
      vehicle_id: day.vehicle_id,
      driver_id: day.user_id,
      machines: day.machines.map((x) => {
        if (typeof x === "object") return x.machine_id;
        return x;
      }),
    })
      .then((response) => {
        if (response.error) {
          NotificationManager.error(
            "No se ha podido guardar " + response.message,
            "¡Error!",
            4000
          );
        } else {
          NotificationManager.success(
            "Se ha guardado correctamente.",
            "¡Éxito!",
            4000
          );
          afterSave();
        }
      })
      .catch((error) => {
        console.error(error);
        NotificationManager.error("No se ha podido guardar", "¡Error!", 4000);
      });
  };

  /** ********************************** */
  /** Typeaheads */
  /** ********************************** */
  const [baseChofers, setBaseChofers] = React.useState([]);
  const [baseVehicles, setBaseVehicles] = React.useState([]);

  const [typeaheadChofer, setTypeaheadChofer] = React.useState({
    isLoading: false,
    options: [],
  });
  const [typeaheadVehicle, setTypeaheadVehicle] = React.useState({
    isLoading: false,
    options: [],
  });

  const getChoferes = React.useCallback((search = "") => {
    setTypeaheadChofer((prev) => ({ ...prev, isLoading: true }));
    apiGetDrivers(search, localStorage.city_id)
      .then((response) => {
        setTypeaheadChofer({ isLoading: false, options: response.rows });
      })
      .catch(console.error);
  }, []);

  const getVehicles = React.useCallback((name = "") => {
    setTypeaheadVehicle((prev) => ({ ...prev, isLoading: true }));
    apiGetVehicles({ name, city_id: localStorage.city_id })
      .then((response) => {
        setTypeaheadVehicle({ isLoading: false, options: response.rows });
      })
      .catch(console.error);
  }, []);

  React.useEffect(() => {
    apiGetDrivers("", localStorage.city_id)
      .then((response) => {
        setBaseChofers(response.rows);
      })
      .catch(console.error);
    apiGetVehicles({ name: "", city_id: localStorage.city_id })
      .then((response) => {
        setBaseVehicles(response.rows);
      })
      .catch(console.error);
  }, []);

  const defaultDriver = React.useMemo(() => {
    if (baseChofers.length && day.user_id) {
      return baseChofers.find((x) => x.id === day.user_id);
    }
    return {
      id: 0,
      username: "",
    };
  }, [day.user_id, baseChofers]);

  const defaultVehicle = React.useMemo(() => {
    if (baseVehicles.length && day.vehicle_id) {
      return baseVehicles.find((x) => x.id === day.vehicle_id);
    }
    return {
      id: 0,
      name: "",
    };
  }, [day.vehicle_id, baseVehicles]);

  return (
    <div className="separate">
      <ul className="nav nav-tabs">
        {programacion &&
          programacion.map((p) => (
            <li className="nav-item">
              <button
                type="button"
                className={`nav-link ${
                  p.day_of_week === day.day_of_week && "active"
                }`}
                onClick={() => setDay(p)}
              >
                {days[p.day_of_week - 1]}
              </button>
            </li>
          ))}
      </ul>
      <div className="card">
        <div className="card-body" key={day.day_of_week}>
          <div className="row">
            <div className="col">
              <h5>Configuración base</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <p className="label-title" style={{ marginBottom: ".5rem" }}>
                Chofer asignado
              </p>
              <AsyncTypeahead
                key={defaultDriver.id}
                id="AsyncTypeaheadChofer"
                labelKey="username"
                multiple={false}
                minLength={3}
                onChange={(elm) =>
                  setDay((prev) => ({
                    ...prev,
                    user_id: elm[0] ? elm[0].id : null,
                  }))
                }
                isLoading={typeaheadChofer.isLoading}
                filterBy={["first_name", "last_name", "username"]}
                selectHintOnEnter={true}
                clearButton={false}
                searchText="Buscando..."
                options={typeaheadChofer.options}
                onSearch={(query) => getChoferes(query)}
                defaultSelected={defaultDriver && [defaultDriver]}
                renderMenuItemChildren={(option, props) => (
                  <div key={option.id}>
                    {option.first_name + " " + option.last_name} -{" "}
                    <small>{option.username}</small>
                  </div>
                )}
              />
            </div>
            <div className="col-md-6 col-12">
              <p className="label-title" style={{ marginBottom: ".5rem" }}>
                Vehículo asignado
              </p>
              <AsyncTypeahead
                key={defaultVehicle.id}
                id="AsyncTypeaheadVehicle"
                labelKey="name"
                multiple={false}
                minLength={3}
                onChange={(elm) =>
                  setDay((prev) => ({
                    ...prev,
                    vehicle_id: elm[0] ? elm[0].id : null,
                  }))
                }
                isLoading={typeaheadVehicle.isLoading}
                filterBy={["name"]}
                selectHintOnEnter={true}
                clearButton={false}
                searchText="Buscando..."
                options={typeaheadVehicle.options}
                onSearch={(query) => getVehicles(query)}
                defaultSelected={defaultVehicle && [defaultVehicle]}
                renderMenuItemChildren={(option, props) => (
                  <div key={option.id}>{option.name}</div>
                )}
              />
            </div>
          </div>

          <div style={{ margin: "1.5rem" }} />
          <div className="row">
            <div className="col">
              <SearchList
                labelKey="name"
                idKey="id"
                onSelect={selectMachine}
                preselectedMachines={day.machines.map((m) => ({
                  id: m.machine_id,
                  name: m.name,
                  serial_number: m.serial_number,
                  city_id: m.city_id,
                }))}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="separate text-right">
                <button
                  type="button"
                  className="btn btn-submit sv-btn"
                  onClick={saveRoute}
                >
                  Guardar configuración
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultWeek;
