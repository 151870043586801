import React from 'react';

const MyEvent = (props) => {
    const { event } = props;
    const indicators = {
        "unassigned": { color: 'bg-red', path: '/cotizaciones', title: 'Sin asignar' },
        "completed": { color: 'bg-green', path: '/diseno', title: 'Completada' },
        "pending-machines": { color: 'bg-yellow', path: '/impresiones', title: 'Máquinas pendientes' },
    }

    return <div className="rc-event-container" onClick={() => event.click(event.start)}>
        <div className="">
            <div className={"bg-indicator " + indicators[event.status].color} key={event.start} title={indicators[event.status].title}>
            </div>
        </div>
    </div>
}

export default MyEvent;