import React from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { apiNewRoute, apiUpdateRoute } from '../../lib/apiRoutes';
import { apiGetDrivers } from '../../lib/apiUsers';
import { apiGetVehicles } from '../../lib/apiVehicles';

const AddRoute = (props) => {
    const [route, setRoute] = React.useState({
        name: '',
        city_id: props.city,
        user_id: 0,
        vehicle_id: 0
    });


    const [typeaheadChofer, setTypeaheadChofer] = React.useState({ isLoading: false, options: [] });
    const [typeaheadVehicle, setTypeaheadVehicle] = React.useState({ isLoading: false, options: [] });
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [selectedVehicle, setSelectedVehicle] = React.useState(null);


    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        console.log("props.editElement", props.editElement);
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
                setRoute(props.editElement);

                if (props.editElement.driver) {
                    console.log("trae driver");
                    let chofer = [];
                    chofer.push(props.editElement.driver);
                    setTypeaheadChofer({ isLoading: false, options: chofer });
                    setSelectedUser(props.editElement.driver);
                }

                if (props.editElement.vehicle) {
                    console.log("trae driver");
                    let vehiculo = [];
                    vehiculo.push(props.editElement.vehicle);
                    setTypeaheadVehicle({ isLoading: false, option: vehiculo });
                    setSelectedVehicle(props.editElement.vehicle);
                }
            }
        }
    }, [props.editElement])

    const submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let obj = { name: route.name, city_id: route.city_id };
            if (selectedUser) {
                obj.user_id = selectedUser.id;
            }

            if (selectedVehicle) {
                obj.vehicle_id = selectedVehicle.id;
            }


            if (isEditing) {
                props.showLoader(true);
                apiUpdateRoute(props.editElement.id, obj)
                    .then(response => {
                        if (response.error) {
                            props.handleClose();
                        }
                        else {
                            props.handleClose({ ...route, ...response });
                        }
                        props.showLoader(false);
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
            else {
                props.showLoader(true);
                apiNewRoute(obj)
                    .then(response => {
                        //Redireccionar a pagina de detalle
                        props.history.push('/rutas/' + response.id);
                        props.handleClose(obj);
                        props.showLoader(false);
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
        }
    }

    const changeForm = (e) => {
        const { name, value } = e.target;
        setRoute({ ...route, [name]: value });
    }


    const typeaheadGetChoferes = (search) => {
        console.log("get elements", search);
        setTypeaheadChofer({ isLoading: true });
        //        setOptions([]);
        apiGetDrivers(search, localStorage.city_id)
            .then(response => {
                setTypeaheadChofer({ isLoading: false, options: response.rows });
            })
            .catch(console.error)
    }

    const typeaheadGetVehicles = (name) => {

        setTypeaheadVehicle({ isLoading: true });
        //        setOptions([]);
        apiGetVehicles({ name, city_id: localStorage.city_id })
            .then(response => {
                setTypeaheadVehicle({ isLoading: false, options: response.rows });
            })
            .catch(console.error)
    }


    const handleChangeChoferSelected = (elm) => {
        if (elm) {
            setSelectedUser(elm[0]);
            setRoute({ ...route, user_id: elm[0].id })
        }
        else {
            setSelectedUser(null);
            setRoute({ ...route, user_id: 0 })
        }
    }

    const handleChangeVehicleSelected = (elm) => {
        if (elm) {
            setSelectedVehicle(elm[0]);
            setRoute({ ...route, vehicle_id: elm[0].id })
        } else {
            setSelectedVehicle(null);
            setRoute({ ...route, vehicle_id: 0 })
        }
    }

    return (
        <form onSubmit={submit}>
            <div className="form-group">
                <label className="label-title">Nombre</label>
                <input name="name" className="form-control" placeholder="Escribe el nombre de la ruta" value={route.name} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Chofer predeterminado</label>
                <AsyncTypeahead
                    id="AsyncTypeaheadChofer"
                    labelKey='username'
                    multiple={false}
                    minLength={3}
                    onChange={handleChangeChoferSelected}
                    isLoading={typeaheadChofer.isLoading}
                    filterBy={['first_name', 'last_name', 'username']}
                    selectHintOnEnter={true}
                    clearButton={false}
                    searchText="Buscando..."
                    options={typeaheadChofer.options}
                    onSearch={(query) => typeaheadGetChoferes(query)}
                    defaultSelected={props.editElement ?
                        (props.editElement.hasOwnProperty("driver") ?
                            [props.editElement.driver] :
                            []) :
                        []}
                    renderMenuItemChildren={(option, props) => (
                        <div key={option.id}>
                            {option.first_name + ' ' + option.last_name} - <small>{option.username}</small>
                        </div>)
                    }
                />
            </div>
            <div className="form-group">
                <label className="label-title">Vehículo predeterminado</label>
                <AsyncTypeahead
                    id="AsyncTypeaheadVehicle"
                    labelKey='name'
                    multiple={false}
                    minLength={3}
                    onChange={handleChangeVehicleSelected}
                    isLoading={typeaheadVehicle.isLoading}
                    filterBy={['name']}
                    selectHintOnEnter={true}
                    clearButton={false}
                    searchText="Buscando..."
                    options={typeaheadVehicle.options}
                    onSearch={(query) => typeaheadGetVehicles(query)}
                    defaultSelected={props.editElement ?
                        (props.editElement.hasOwnProperty("vehicle") ?
                            [props.editElement.vehicle] :
                            []) :
                        []}
                    renderMenuItemChildren={(option, props) => (
                        <div key={option.id}>
                            {option.name}
                        </div>)
                    }
                />
            </div>
            <div className="tac">
                <button type="submit" className="btn btn-submit sv-btn" >Guardar</button>
            </div>
        </form>
    )
}

export default AddRoute;