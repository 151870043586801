import React from 'react';
import { baseUrl } from '../../lib/constants';
import { apiGenerateBarCode } from '../../lib/apiWarehouses';
import { apiGenerateProductBarCode } from '../../lib/apiProducts';

const CodePreview = (props) => {
    const { id, isProduct, shouldUpdate, setShouldUpdate } = props;
    const [img, setImg] = React.useState(null);

    React.useEffect(() => {
        if (shouldUpdate) {
            if (id > 0) {
                if (isProduct) {
                    apiGenerateProductBarCode(id)
                        .then(convertBlobToBase64)
                        .then(response => {
                            setImg(response);
                        })
                        .catch(error => { console.error("no se pudo generar imagen", error); });
                    setShouldUpdate(false);
                }
                else {
                    apiGenerateBarCode(id)
                        .then(convertBlobToBase64)
                        .then(response => {
                            setImg(response);
                        })
                        .catch(error => { console.error("no se pudo generar imagen", error); });
                    setShouldUpdate(false);
                }
            }
        }
    }, [id, isProduct, shouldUpdate, setShouldUpdate])

    const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new window.FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

    return <div className="col card">
        <div className="card-body">
            <h4>Código de barras</h4>
            <div className="separate">
                <a href={img} download={img} target="_blank" rel="noopener noreferrer" className="btn btn-primary sv-btn">Descargar</a>
            </div>
            <img className="img code-bar" src={img} alt="" />
        </div>
    </div>
}

CodePreview.defaultProps = {
    isProduct: false,
    shouldUpdate: true,
    setShouldUpdate: (state) => { }
}

export default CodePreview;