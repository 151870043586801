import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'vehicle/';

export const apiGetVehicles = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleVehicle = async (id) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiNewVehicle = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateVehicle = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetVehicleMovements = async () => {
    let url = `${urlAPI}operation`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiRegisterVehicleInventoryMovement = async (id, obj) => {
    let url = urlAPI + id;
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}