import React from 'react';
import { apiNewProduct, apiUpdateProduct } from '../../lib/apiProducts';

const AddProduct = (props) => {
    const [product, setProduct] = React.useState({
        name: '',
        city_id: props.city,
        description: '',
        code: '',
        price: ''
    });
    const [isEditing, setIsEditing] = React.useState(false);

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
                setProduct(props.editElement);
            }
        }
    }, [props.editElement])

    const submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {
            let obj = product;

            if (isEditing) {
                props.showLoader(true);
                let objEdit = { name: obj.name, code: obj.code, price: parseFloat(obj.price), description: obj.description, city_id: obj.city_id, active: obj.active };
                apiUpdateProduct(props.editElement.id, objEdit)
                    .then(response => {
                        if (response.message) {
                            props.handleClose(null, 'error', response);
                            props.showLoader(false);
                            props.setUpdateLogs(true);
                        }
                        else {
                            props.handleClose(obj, "success", response);
                            props.showLoader(false);
                            props.setUpdateLogs(true);
                        }
                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
            else {
                props.showLoader(true);
                apiNewProduct({ ...obj, price: parseFloat(obj.price), city_id: parseInt(obj.city_id) })
                    .then(response => {
                        //Redireccionar a pagina de detalle
                        if (response.message) {
                            props.handleClose(null, 'error', response);
                            props.showLoader(false);
                        }
                        else {
                            props.handleClose(obj, "success", response);
                            props.showLoader(false);
                            props.history.push('/productos/' + response.id);
                        }


                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
        }
    }

    const changeForm = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    }

    return (
        <form onSubmit={submit}>
            <div className="form-group">
                <label className="label-title">Nombre</label>
                <input name="name" className="form-control" placeholder="Escribe el nombre del producto" value={product.name} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Código</label>
                <input name="code" placeholder="Ingrese código del producto" className="form-control" value={product.code} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Precio</label>
                <input name="price" placeholder="Ingrese código del producto" className="form-control" value={product.price} onChange={changeForm} required />
            </div>
            <div className="form-group">
                <label className="label-title">Descripción</label>
                <textarea name="description" placeholder="Ingrese descripción del producto" className="form-control" value={product.description} onChange={changeForm} required />
            </div>
            <div className="tac">
                <button type="submit" className="btn btn-submit sv-btn" >Guardar</button>
            </div>
        </form>
    )
}

export default AddProduct;