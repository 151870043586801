import { baseUrl, GET, POST, PUT, DELETE, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'route/';

export const apiGetRoutes = async (obj) => {
    let url = `${urlAPI}list?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetSingleRoute = async (id) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetMonthProgram = async (id, date) => {
    let url = `${urlAPI}${id}/status?date=${date}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetConfigRoute = async (id, date) => {
    let url = `${urlAPI}${id}?date=${date}`;
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiSetRouteConfig = async (id, obj) => {
    let url = `${urlAPI}${id}`;
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiSetDefaultRouteConfig = async (id, obj) => {
    let url = `${urlAPI}default/${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiUpdateRouteConfig = async (id, obj) => {
    let url = `${urlAPI}${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}


export const apiNewRoute = async (obj) => {
    const request = await CreateAuthRequest(POST, obj, true);
    return fetch(urlAPI, request)
        .then(response => response.json())
}

export const apiUpdateRoute = async (id, obj) => {
    let url = `${urlAPI}?id=${id}`;
    const request = await CreateAuthRequest(PUT, obj, true);
    return fetch(url, request)
        .then(response => response.json())
}