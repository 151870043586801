import React from 'react';
import { apiGetUsers } from '../../lib/apiUsers';
import { roles } from '../../lib/constants';
import { Link } from 'react-router-dom';
import Catalogo from '../../General Components/Catalogo/catalogo';
import AddUser from './usersAdd';

const UsersList = (props) => {

    const mapData = (data, edit, details) => {
        let datatable = [];

        datatable = data.map(elm => ({
            name: `${elm.first_name} ${elm.last_name}`,
            rol: roles.find(rol => rol.id === elm.role_id).name,
            opciones: <React.Fragment>
                <button className="btn btn-edit-table" onClick={() => edit(elm)}>
                    <i className="fas fa-edit" /></button>
                <button className="btn  btn-primary sv-btn" onClick={() => edit({ ...elm, isEditingPassword: true })}>
                    <i className="fas fa-lock" /></button>
                <button className="btn btn-primary sv-btn" onClick={() => details(elm)}>Ver más</button>
            </React.Fragment>
        }))

        return datatable;
    }

    return (
        <Catalogo
            module="users"
            table={{ columns: ["Nombre", 'Rol', 'Opciones'] }}
            GetData={apiGetUsers}
            title="Administrar Usuarios"
            filters={{ page: 1, limit: 10, city_id: props.city }}
            mapData={mapData}
            {...props}
            modalAdd={{ title: "Crear nuevo usuario", titleEdit: "Editar usuario", Form: (props) => <AddUser {...props} /> }}
        />
    )
}

export default UsersList;

