import React from 'react';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';

function VirtualizedList({ hasNextPage, isNextPageLoading, list, loadNextPage, loadContent, className }) {
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const rowCount = hasNextPage
        ? list.length + 1
        : list.length

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreRows = isNextPageLoading
        ? () => { }
        : loadNextPage

    // const loadMoreRows = loadNextPage

    // Every row is loaded except for our loading indicator row.
    const isRowLoaded = ({ index }) => !hasNextPage || index < list.length

    // Render a list item or a loading indicator.
    const rowRenderer = ({ index, key, style }) => {
        let content

        if (!isRowLoaded({ index })) {
            content = 'Loading...'
        } else {
            content = loadContent(list[index])//<img src={list[index].url} alt="xd" style={{ height: "90%", width: "auto" }} />
        }

        return (
            <div key={key} style={style} className={content.className} >
                {content.content}
            </div >
        )
    }

    return (


        <AutoSizer disableHeight className="separate">
            {({ height, width }) => (
                <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    rowCount={rowCount}
                >
                    {({ onRowsRendered, registerChild }) => (
                        <List

                            ref={registerChild}
                            onRowsRendered={onRowsRendered}
                            rowRenderer={rowRenderer}
                            rowHeight={32}
                            width={width}
                            height={200}
                            rowCount={rowCount}
                        />
                    )}

                </InfiniteLoader>
            )}
        </AutoSizer>
    )
}

VirtualizedList.defaultProps = {
    height: 20,
    hasNextPage: false,
    isNextPageLoading: false,
    list: [],
    loadNextPage: () => { },
    loadContent: () => (<div></div>)
}

export default VirtualizedList;