import { baseUrl, GET, CreateAuthRequest, makeUrlGET } from './constants';

const urlAPI = baseUrl + 'notification/';

export const apiGetNotifications = async (obj) => {
    let url = `${urlAPI}?` + makeUrlGET(obj);
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}

export const apiGetNotSeenNotifications = async () => {
    let url = urlAPI + 'nonviewed';
    const request = await CreateAuthRequest(GET, null, true);
    return fetch(url, request)
        .then(response => response.json())
}