import React from 'react';
import { withRouter } from 'react-router-dom';
import { apiGetSingleVehicle } from '../../lib/apiVehicles';
import ModalAdd from '../../General Components/Catalogo/modalAdd';
import FormAdd from './addVehicle';
import moment from 'moment';
import LogsTable from '../../General Components/LogsTable/logsTable';
import Inventory from '../../General Components/Inventory/inventory';
import CodePreview from '../../General Components/CodePreview/codePreview';
import { apiGetInventoryWarehouse } from '../../lib/apiWarehouses';

const reducer = (state, action) => {
    switch (action.type) {
        case "setVehicle":
            return { ...state, vehicle: action.payload };
        case "setShow":
            return { ...state, show: action.payload }
        case "setUpdateCode":
            return { ...state, shouldUpdateCode: action.payload }
        case "setUpdateLogs":
            return { ...state, updateLogs: action.payload }
        default:
            return { ...state }
    }
}

const initialState = {
    vehicle: { name: '', updatedAt: '', license_plate: '' },
    show: false,
    shouldUpdateCode: true,
    updateLogs: true
};

const Details = (props) => {
    const { id } = props.match.params;
    const { NotificationManager } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);
    const { vehicle, show, shouldUpdateCode, updateLogs } = state;

    React.useEffect(() => {
        apiGetSingleVehicle(id)
            .then(response => {
                if (response.type) {

                }
                else {
                    dispatch({ type: 'setVehicle', payload: response });
                }
            })
    }, [id])

    moment.updateLocale('es', {
        monthsShort: [
            "Ene", "Feb", "Mar", "Abr", "May", "Jun",
            "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
        ],
        months: [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ]
    });

    const handleClose = (obj) => {
        if (obj) {
            dispatch({ type: 'setVehicle', payload: obj });
            dispatch({ type: 'setUpdateCode', payload: true });
            NotificationManager.success('Se ha guardado correctamente.', '¡Éxito!', 4000);
        }
        dispatch({ type: 'setShow', payload: false });
    }

    const showModal = (obj) => {
        dispatch({ type: 'setShow', payload: true });
    }

    const setShouldUpdateCode = (status) => {
        dispatch({ type: 'setUpdateCode', payload: status })
    }
    const setUpdateLogs = (payload) => {
        dispatch({ type: 'setUpdateLogs', payload })
    }

    return (
        <div className="details">
            <div className="row">
                <div className="col-md-6">
                    <h3><i className="fas fa-arrow-left" onClick={() => { props.history.goBack() }}></i> Detalles de vehículo</h3>
                </div>
                <div className="col-md-6 tar">
                    <button className="btn btn-success sv-btn" onClick={() => showModal(vehicle)}><i className="fas fa-edit" /> Editar</button>
                </div>
            </div>
            <div className="row no-row-margin">
                <CodePreview id={id}
                    shouldUpdate={shouldUpdateCode}
                    setShouldUpdate={setShouldUpdateCode}
                />
                <div className="col card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <p className="label-title">Nombre del vehículo</p>
                                <label className="data">{vehicle.name}</label>
                            </div>
                            <div className="col-md-6 col-12">
                                <p className="label-title">Monto en vehículo</p>
                                <label className="data">$ {parseFloat(vehicle.balance).format(2)}</label>
                            </div>
                            <div className="col-md-6 col-12">
                                <p className="label-title">Placas</p>
                                <label className="data">{vehicle.license_plate}</label>
                            </div>
                            <div className="col-md-6 col-12">
                                <p className="label-title">Última actualización</p>
                                <label className="data">{moment(vehicle.updatedAt).format('DD/MMM/YYYY hh:mm a')}</label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Inventory
                GetData={apiGetInventoryWarehouse}
                id={id}
                setUpdateLogs={setUpdateLogs}
                NotificationManager={NotificationManager}
            />

            <LogsTable
                id={id}
                updateLogs={updateLogs}
                setUpdateLogs={setUpdateLogs}
            />
            <ModalAdd
                component={(propss) => <FormAdd {...propss} />}
                editElement={vehicle}
                show={show}
                city={props.city_id}
                handleClose={handleClose}
                title={'Crear nuevo usuario'}
                titleEdit={'Editar usuario'}
                {...props}
            />
        </div>)
}

export default withRouter(Details);