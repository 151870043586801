import React from "react";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { apiResetPassword } from '../../lib/apiAuth';

import { NotificationContainer, NotificationManager } from 'react-notifications';


import logo from '../../Imgs/logo-mmnt.png';
import Loader from '../../General Components/Loader/';
import jwtDecode from 'jwt-decode';
import './login.scss';

const ShowPasswordMessage = ({ match }) => {

    const message = match ? "Las contraseñas coinciden." : "Las contraseñas no coinciden."
    let label = "";
    if (match !== undefined) {
        label = <label className={match ? "match" : "unmatch"}>
            <i className={match ? "fas fa-check" : "fas fa-times"} /> {message}
        </label>;
    }

    return label;
}

const ResetPassword = (props) => {
    const [state, setState] = React.useState({
        password: '',
        confirmPassword: '',
        match: undefined,
        code: '',
    })
    const [showLoader, setShowLoader] = React.useState(false);

    const { password, confirmPassword, match, emailValid, email, code } = state;

    React.useEffect(() => {
        const { token } = props.match.params;
        setState({ ...state, code: token });

    }, [props.match.params])

    const Submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity() && state.match) {
            setShowLoader(true);
            apiResetPassword(code, { password })
                .then(response => {
                    setShowLoader(false);
                    if (response.message !== "Conectado con exito.") {
                        NotificationManager.error('Ocurrio un error al cambiar su contraseña. ' + response.message, 'Error de validación', 4000);
                    }
                    else {
                        localStorage.setItem('token', response.token);
                        localStorage.setItem("city_id", jwtDecode(response.token).city_id);
                        NotificationManager.success('', '¡Bienvenido!', 700);

                        setTimeout(() => {
                            props.history.push('/monitor');
                        }, 700);
                    }
                })
                .catch(error => {
                    setShowLoader(false);
                    NotificationManager.error('Inténtelo más tarde.', 'Ocurrió un error', 4000)
                })

        }
    }

    const ChangePassword = (e) => {
        const { name, value } = e.target;

        if (name === "confirmPassword") {
            if (state.password.length >= 4) {
                setState({ ...state, [name]: value, match: state.password === value });
            }
            else {
                setState({ ...state, [name]: value, match: undefined });
            }
        }
        else {
            if (state.confirmPassword.length >= 4) {
                setState({ ...state, [name]: value, match: state.confirmPassword === value });
            }
            else {
                setState({ ...state, [name]: value, match: undefined });
            }
        }
    }


    return (
        <div className="login">
            <div className="container">
                <div className="card" >
                    <div className="card-body">
                        <div className="validate-email-message" >
                            <div>
                                <img src={logo} alt="multirol" className="logo" />
                            </div>
                            <div className="tac">
                                <label className="welcome">Cambiar contraseña. </label>
                            </div>
                            <hr />
                            <form onSubmit={Submit}>
                                <div className="form-group">
                                    <label className="label-title">Nueva contraseña</label>
                                    <input
                                        className="form-control"
                                        name="password"
                                        type="password"
                                        value={password}
                                        onChange={ChangePassword}
                                        minLength="4"
                                        required
                                        autoComplete="new-password"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="label-title">Confirmar contraseña</label>
                                    <input
                                        className="form-control"
                                        name="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={ChangePassword}
                                        minLength="4"
                                        required
                                    />
                                </div>
                                <div className="">
                                    <button className="btn btn-primary" type="submit">Guardar</button>
                                </div>
                                <div className="">
                                    <ShowPasswordMessage match={match} />
                                </div>
                            </form>
                            <div className="forgot-password">
                                <Link to="/login">Iniciar Sesión</Link>
                            </div>
                        </div>
                        <NotificationContainer />
                        <Loader showLoader={showLoader} />
                    </div>
                </div>
            </div>
        </div>)
}

export default withRouter(ResetPassword);