import React from 'react';
import { roles } from '../../lib/constants';
import { apiNewUser, apiUpdateUser } from '../../lib/apiUsers';


const ShowPasswordMessage = ({ match }) => {

    const message = match ? "Las contraseñas coinciden." : "Las contraseñas no coinciden."
    let label = "";
    if (match !== undefined) {
        label = <label className={match ? "match" : "unmatch"}>
            <i className={match ? "fas fa-check" : "fas fa-times"} /> {message}
        </label>;
    }

    return label;
}


const UsersAdd = (props) => {
    const [user, setUser] = React.useState({
        first_name: '',
        last_name: '',
        username: '',
        password: '',
        city_id: props.city,
        role_id: 1,
        email: '',
        confirmPassword: ''
    });
    const [passwords, setPasswords] = React.useState({ password: '', confirmPassword: '', match: undefined });
    const [isEditing, setIsEditing] = React.useState(false);
    const [isEditingPassword, setIsEditingPassword] = React.useState(false);

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.id) {
                setIsEditing(true);
                setUser(props.editElement);
            }
        }
        else {
            setUser({
                first_name: '',
                last_name: '',
                username: '',
                password: '',
                city_id: props.city,
                role_id: 1,
                email: '',
                confirmPassword: ''
            });
        }
    }, [props.editElement, props.city])

    React.useEffect(() => {
        if (props.editElement) {
            if (props.editElement.isEditingPassword) {
                setIsEditingPassword(true);
            }
            else {
                setIsEditingPassword(false);
            }
        }
        else {
            setIsEditingPassword(false);
        }
    }, [props.editElement])


    const submit = (e) => {
        e.preventDefault();
        if (e.target.checkValidity()) {

            let obj = {};
            const { confirmPassword, ...form } = user;
            if (parseInt(user.role_id, 10) === 3) {
                obj = form;
                delete obj.email;
            }
            else {
                obj = form;
                delete obj.username;
            }

            if (isEditing) {
                props.showLoader(true);
                apiUpdateUser(props.editElement.id, { ...obj, role_id: parseInt(obj.role_id, 10) })
                    .then(response => {

                        if (response.type) {
                            props.showLoader(false);
                            props.handleClose();
                        }
                        else {
                            props.handleClose({ ...obj, role_id: parseInt(obj.role_id, 10) });
                            props.showLoader(false);
                        }

                    })
                    .catch(error => {
                        //Mostrar notificacion de error
                        props.handleClose();
                        props.showLoader(false);
                    })
            }
            else {
                props.showLoader(true);
                if (passwords.match) {
                    apiNewUser(obj)
                        .then(response => {
                            props.history.push('/usuarios/' + response.id);
                            props.handleClose();
                            props.showLoader(false);
                        })
                        .catch(error => {
                            //Mostrar notificacion de error
                            props.handleClose();
                            props.showLoader(false);
                        })
                }
            }
        }

    }

    const changeForm = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }

    const ChangePassword = (e) => {
        const { name, value } = e.target;

        if (name === "confirmPassword") {
            if (passwords.password.length >= 4) {
                setPasswords({ ...passwords, [name]: value, match: passwords.password === value });
            }
            else {
                setPasswords({ ...passwords, [name]: value, match: undefined });
            }
        }
        else {
            setUser({ ...user, password: value })
            if (passwords.confirmPassword.length >= 4) {
                setPasswords({ ...passwords, [name]: value, match: passwords.confirmPassword === value });
            }
            else {
                setPasswords({ ...passwords, [name]: value, match: undefined });
            }
        }
    }


    const handleRoleId = (id) => {
        if (parseInt(id, 10) === 3) {
            return <div className="form-group">
                <label className="label-title">Nombre de usuario</label>
                <input name="username" className="form-control" value={user.username} onChange={changeForm} required />
            </div>
        }
        else {
            return <div className="form-group">
                <label className="label-title">Email</label>
                <input type="email" name="email" className="form-control" value={user.email} onChange={changeForm} required />
            </div>
        }
    }


    return (
        <form onSubmit={submit}>
            {
                !isEditingPassword &&
                <React.Fragment>
                    <div className="form-group">
                        <label className="label-title">Nombre</label>
                        <input name="first_name" className="form-control" placeholder="Escribe el nombre del usuario" value={user.first_name} onChange={changeForm} required />
                    </div>
                    <div className="form-group">
                        <label className="label-title">Apellido</label>
                        <input name="last_name" className="form-control" placeholder="Escribe el apellido del usuario" value={user.last_name} onChange={changeForm} required />
                    </div>
                    <div className="form-group">
                        <label className="label-title">Rol</label>
                        <select className="form-control" name="role_id" value={user.role_id} onChange={changeForm} required>
                            {
                                roles.map(rol =>
                                    <option key={rol.id} value={rol.id}>{rol.name}</option>
                                )
                            }
                        </select>
                    </div>
                </React.Fragment>
            }

            {
                !isEditingPassword &&
                handleRoleId(user.role_id)
            }

            {
                (!isEditing || isEditingPassword) &&
                <React.Fragment>
                    <div className="form-group">
                        <label className="label-title">Contraseña</label>
                        <input type="password" name="password" placeholder="Escribe la contraseña" className="form-control" value={user.password} onChange={ChangePassword} required />
                    </div>
                    <div className="form-group">
                        <label className="label-title">Confirmar contraseña</label>
                        <input type="password" name="confirmPassword" className="form-control" placeholder="Repita la contraseña" value={passwords.confirmPassword} onChange={ChangePassword} required />
                    </div>
                </React.Fragment>
            }


            <div className="">
                <ShowPasswordMessage match={passwords.match} />
            </div>

            <div className="tac">
                <button type="submit" className="btn btn-submit sv-btn" >Guardar</button>
            </div>
        </form>
    )
}

export default UsersAdd;